interface IError {
  timestamp: string
  status: boolean
  message: string
  data: {
    [key: string]: [string]
  }
}

export const getErrors = (error: IError) => {
  if (error?.message === 'error.400') {
    if (Object.keys(error?.data)?.length) {
      let errors: any = error?.data

      Object.keys(error?.data)?.forEach(key => {
        errors = {
          ...errors,
          [key]: {
            message: error?.data?.[key]?.[0],
            ref: { name: key },
            type: 'request'
          }
        }
      })
      return errors
    } else {
      return {}
    }
  } else {
    return {}
  }
}

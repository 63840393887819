import GetContainer from '../../../components/get-container'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RandomContext } from '../../../providers/RandomProvider'

export const Messages = () => {
  const { mutate } = useCustomMutation('/student/chats', 'POST')
  const [message, setMessage] = useState('')
  const ref = useRef<any>(null)
  const { t } = useTranslation()
  const { firebaseRandom } = useContext(RandomContext)
  const [messageData, setMessageData] = useState<any>(null)
  return (
    <GetContainer
      url='/student/chats'
      params={{ random: firebaseRandom }}
      hideLoading
      onSuccess={data => {
        setMessageData(data?.data)
        setTimeout(() => {
          ref.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }}
    >
      {({ refetch }) => (
        <div className='messages-wrap'>
          <ul className='messages-list' ref={ref} style={{ minHeight: '100%' }}>
            {messageData?.messages?.map((item: any) => (
              <li key={item?.id} className={item?.position}>
                {item?.message}
              </li>
            ))}
          </ul>
          <div className='messages-form'>
            <input type='text' value={message} onChange={e => setMessage(e.target?.value)} placeholder={t('Message')} />
            <button
              className='btn'
              onClick={() =>
                mutate(
                  {
                    message: message,
                    file: '',
                    ...(!messageData?.id ? { user_id: messageData?.user_id } : { chat_id: messageData?.id })
                  },
                  {
                    onSuccess(data, variables, context) {
                      setMessage('')
                      refetch()
                    }
                  }
                )
              }
            >
              {t('Send')}
            </button>
          </div>
        </div>
      )}
    </GetContainer>
  )
}

import React, { SVGProps } from 'react'

function Profile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={25} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.04 2.63184C9.42 2.63184 7.29 4.76184 7.29 7.38184C7.29 9.95184 9.3 12.0318 11.92 12.1218C12 12.1118 12.08 12.1118 12.14 12.1218H12.21C13.4394 12.0808 14.6046 11.5633 15.4593 10.6787C16.314 9.79417 16.7912 8.61188 16.79 7.38184C16.79 4.76184 14.66 2.63184 12.04 2.63184ZM17.12 14.7808C14.33 12.9208 9.78 12.9208 6.97 14.7808C5.7 15.6308 5 16.7808 5 18.0108C5 19.2408 5.7 20.3808 6.96 21.2208C8.36 22.1608 10.2 22.6308 12.04 22.6308C13.88 22.6308 15.72 22.1608 17.12 21.2208C18.38 20.3708 19.08 19.2308 19.08 17.9908C19.07 16.7608 18.38 15.6208 17.12 14.7808Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Profile

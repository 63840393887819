import GetContainer from '../../../components/get-container'
import { INotification } from '../../../types/types'
import NotificationCard from './components/NotificationCard'

export const Notifications = () => (
  <div className='messages-wrap'>
    <GetContainer url='student/notifications'>
      {({ data }) => (
        <ul className='messages-nots'>
          {data?.data?.map((item: INotification) => (
            <NotificationCard item={item} key={item?.id} />
          ))}
        </ul>
      )}
    </GetContainer>
  </div>
)

import React, { useState } from 'react'
import './TimePicker.scss'
import GetContainer from '../../../../../components/get-container'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
}

const TimePicker: React.FC<IProps> = props => {
  const [startTime, setStartTime] = useState<string>('00:00')
  const [endTime, setEndTime] = useState<string>('00:00')

  const handleSave = () => {
    alert(`Time saved: ${startTime} - ${endTime}`)
  }

  const handleCancel = () => {
    setStartTime('00:00')
    setEndTime('00:00')
  }

  return (
    <GetContainer url='teacher/slots/time'>
      {({ data }) => (
        <div className='timepicker-container'>
          <div className='time-inputs'>
            <div className='time-select'>
              <Controller
                control={props.control}
                name='start_time'
                render={({ field: { onChange, value } }) => (
                  <select value={value} onChange={e => onChange(e.target.value)}>
                    {data?.map((time: string) => (
                      <option value={time} key={time + 'start'}>
                        {time}
                      </option>
                    ))}
                    {/* Add more options here */}
                  </select>
                )}
              />
            </div>
            <span> - </span>
            <div className='time-select'>
              <Controller
                control={props.control}
                name='end_time'
                render={({ field: { onChange, value } }) => (
                  <select value={value} onChange={e => onChange(e.target.value)}>
                    {data?.map((time: string) => (
                      <option value={time} key={time + 'end'}>
                        {time}
                      </option>
                    ))}
                    {/* Add more options here */}
                  </select>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </GetContainer>
  )
}

export default TimePicker

import { useTranslation } from 'react-i18next'
import LiveLessonCard from './components/LiveLessonCard'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import { ILiveLesson } from '../../../types/types'

export const LiveLessons = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='live-main'>
      <ul className='live-head'>
        <li className='current' onClick={() => navigate('/student/home/live-lessons')}>
          {t('Todays program')}
        </li>
        <li onClick={() => navigate('/student/home/library')}>{t('Writing lessons')}</li>
      </ul>
      <ul className='live-list'>
        <GetContainer url='student/videos'>
          {({ data }) => data?.data?.map((item: ILiveLesson) => <LiveLessonCard item={item} key={item.id} />)}
        </GetContainer>
      </ul>
      <div className='live-video'>
        <div className='live-video__content'>
          <div className='live-video__iframe'>
            <iframe src='' title='live-video' frameBorder={0} />
          </div>
          <div className='live-video__name'>Morning Yoga</div>
          <div className='live-item__desc'>
            Game-Based Learning,Phonics Bootcamp, <br />
            Bootcamp,ELA Standard,Story Time,Writing Bootcamp,Book Club,Book
          </div>
        </div>
      </div>
    </div>
  )
}

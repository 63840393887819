import GetContainer from '../../../components/get-container'
import WithHTML from '../../../components/with-html'
import { MEDIA_URL } from '../../../configs/request'
import { useTranslation } from 'react-i18next'

export const Requirements = () => {
  const { i18n } = useTranslation()
  return (
    <div className='requirements-info'>
      <GetContainer url='student/system_requirements'>
        {({ data }) => (
          <div className='support-content'>
            <h2 className='page-title'>{data?.data?.title?.[i18n.language]}</h2>
            <img src={MEDIA_URL + data?.data?.photo} alt='systemRequirementsText' />
            <div className='support__text'>
              <WithHTML html={data?.data?.description?.[i18n.language]} />
            </div>
          </div>
        )}
      </GetContainer>
    </div>
  )
}

import * as React from "react"
import { SVGProps } from "react"
const Coin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#FB9851"
      d="M16 2.667C8.64 2.667 2.665 8.64 2.665 16s5.973 13.334 13.333 13.334S29.333 23.36 29.333 16c0-7.36-5.974-13.333-13.334-13.333ZM21 15c.546 0 1 .454 1 1 0 .547-.454 1-1 1h-9.667v.334c0 1.653 1.346 3 3 3h6.666c.547 0 1 .453 1 1 0 .546-.453 1-1 1h-6.666c-2.76 0-5-2.24-5-5v-2.667c0-2.76 2.24-5 5-5h6.666c.547 0 1 .453 1 1 0 .547-.453 1-1 1h-6.666c-1.654 0-3 1.347-3 3V15h9.666Z"
    />
  </svg>
)
export default Coin

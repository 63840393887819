import * as React from 'react'
import { SVGProps } from 'react'
const LiveLessons = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={12} cy='12.6318' r={11} fill='#FB9851' fillOpacity='0.1' />
    <circle cx={12} cy='12.6318' r={8} fill='#FB9851' fillOpacity='0.3' />
    <circle cx={12} cy='12.6318' r={5} fill='#FB9851' />
  </svg>
)
export default LiveLessons

import { useState } from 'react'
import SuccessModal from '../../../components/modals/SuccessModal'
import MyCalendar from './component/bigCalendar'
import MonthlySlot from './component/monthly-slot'

export const TeacherHome = () => {
  const [open, setOpen] = useState(false)

  return (
    <div className='calendar-main'>
      <MonthlySlot />
      <MyCalendar openModal={() => setOpen(true)} />
      <SuccessModal message='Slot created successfully' open={open} onClose={() => setOpen(false)} />
    </div>
  )
}

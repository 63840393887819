import React, { useState } from 'react'
import Calendar from '../../../../assets/img/icons/Calendar'
import Coin from '../../../../assets/img/icons/Coin'
import RequestModal from '../../../../components/request-modal'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'
import { ISchedule, ISubject, ITeacher } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { MEDIA_URL } from '../../../../configs/request'

interface IProps {
  slot: ISchedule
  subject: ISubject
  teacher: ITeacher
}

function IndividualLessonItem(props: IProps) {
  const { mutate, isSuccess, error } = useCustomMutation('/student/slots/reserve', 'POST')
  const { i18n, t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <div className='individual-item'>
      <div className='individual-item__head'>
        <div className='individual-item__ico'>
          <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
              stroke='currentColor'
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <div className='individual-item__wrap'>
          <div className='individual-item__name'>{props.subject?.name?.[i18n.language]}</div>
          <div className='individual-item__price'>
            {/* <Coin /> */}
            {/* <span>{props.subject?} coin</span> */}
          </div>
        </div>
      </div>
      <div className='individual-item__date'>
        <Calendar />
        <span>{moment(props.slot?.date)?.format('ddd, MMM D, YYYY')}</span>
      </div>
      <div className='individual-item__time'>
        <Calendar />
        <span> {props.slot?.time}</span>
      </div>
      <div className='individual-item__user'>
        <img
          src={
            props.teacher?.photo ? MEDIA_URL + props.teacher?.photo : require('../../../../assets/img/mock/student.jpg')
          }
          alt='student'
        />
        <span>{`${props.teacher?.firstname} ${props.teacher?.lastname}`}</span>
      </div>
      <div className='individual-item__btn'>
        <button className='btn' onClick={() => setOpen(true)}>
          {t('Book lesson')}
        </button>
      </div>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title='Request individual title'
        error={error}
        isSuccess={isSuccess}
        description={
          <>
            <p>{props.subject?.name?.[i18n.language]}</p>
            <p>
              {moment(props.slot?.date)?.format('ddd, MMM D, YYYY')} {props.slot?.time}
            </p>
          </>
        }
        send={() =>
          mutate({
            subject_id: props.subject?.id,
            slot_id: props.slot?.id
          })
        }
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle='Request individual success title'
        successDesc='Request individual success desc'
        errorTitle='Request individual success title'
      />
    </div>
  )
}

export default IndividualLessonItem

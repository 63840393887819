import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import TimezonePicker from 'react-timezone'

type CategoryInput = {
  name: string
  label: string
  placeholder: string
  control: Control
  disabled?: boolean
  error: any
  inputProps?: any
}

const TimeZoneSelect: React.FC<CategoryInput> = ({ name, label, placeholder, control, error, disabled }) => {
  const { t } = useTranslation()

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__item'>
          <div className='login-form__name'>{t(label)}</div>
          {/* <div className=''> */}
          <TimezonePicker
            value={field.value}
            className='login-form__input'
            onChange={(timezone: string) => field.onChange(timezone)}
            inputProps={{
              placeholder: t('Select Timezone...'),
              name: 'timezone',
              style: {
                width: '100% !important'
              }
            }}
          />
          {/* </div> */}
          {error?.message && <div className='login-form__advice'>{t(String(error?.message))}</div>}
        </div>
      )}
    />
  )
}

export default TimeZoneSelect

import GetContainer from '../../../components/get-container'
import { ISubject } from '../../../types/types'
import CourseCard from './components/CourseCard'

export const Courses = () => {
  return (
    <div className='course-list'>
      <GetContainer url='student/subjects'>
        {({ data }) => data?.data?.map((item: ISubject) => <CourseCard key={item.id} item={item} />)}
      </GetContainer>
    </div>
  )
}

import GetContainer from '../../../components/get-container'
import { ITariff } from '../../../types/types'
import TariffCard from './components/TariffCard'

export const Payment = () => (
  <div>
    <GetContainer url='student/tariffs'>
      {({ data }) => (
        <ul className='rates-list'>
          {data?.data?.map((tariff: ITariff) => (
            <TariffCard tariff={tariff} key={tariff.id} />
          ))}
        </ul>
      )}
    </GetContainer>
    <div className='calc-card'>
      <div className='calc-content'>
        <div className='calc__title'>Classes per week</div>
        <div className='calc__text'>
          Our free Starter Site plan is great for trying Webflow. Purchase a paid Site plan to publish, host, and unlock
          additional features.
        </div>
      </div>
      <div className='calc-wrap'>
        <div className='calc-input'>
          <div className='calc-input__coin'>Coin</div>
          <div className='calc-input__input'>
            <input type='text' />
          </div>
          <div className='calc-input__values'>
            <span className='active'>100</span>
            <span>200</span>
            <span>300</span>
            <span>400</span>
            <span>500</span>
            <span>600</span>
            <span>700</span>
            <span>800</span>
            <span>900</span>
            <span>1000</span>
          </div>
        </div>
        <div className='calc-info'>
          <div className='calc-info__price'>
            <div>
              <span>1</span> mln
            </div>
            <div>
              <span>100</span> ming
            </div>
            <div className='som'>so’m</div>
          </div>
          <a href='#' className='btn btn-lightgreen'>
            Sotib olish
          </a>
        </div>
      </div>
    </div>
  </div>
)

import React, { SVGProps } from 'react'

function Invite(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.0002 14C6.99016 14 2.91016 17.36 2.91016 21.5C2.91016 21.78 3.13016 22 3.41016 22H20.5902C20.8702 22 21.0902 21.78 21.0902 21.5C21.0902 17.36 17.0102 14 12.0002 14ZM12.0002 2C10.8202 2 9.74016 2.41 8.88016 3.1C8.29214 3.56546 7.81742 4.1583 7.49177 4.83385C7.16612 5.50941 6.99802 6.25006 7.00016 7C7.00016 7.94 7.26016 8.82 7.73016 9.57C8.16875 10.3117 8.79341 10.9261 9.54233 11.3523C10.2912 11.7785 11.1385 12.0017 12.0002 12C13.2602 12 14.4102 11.54 15.2902 10.75C15.6802 10.42 16.0202 10.02 16.2802 9.57C16.7402 8.82 17.0002 7.94 17.0002 7C17.0002 4.24 14.7602 2 12.0002 2ZM14.5902 6.46L11.9202 8.92C11.7402 9.09 11.5102 9.17 11.2802 9.17C11.1578 9.17063 11.0365 9.1471 10.9233 9.10077C10.81 9.05444 10.707 8.98621 10.6202 8.9L9.39016 7.66C9.30229 7.57297 9.23255 7.46939 9.18495 7.35525C9.13735 7.24111 9.11285 7.11867 9.11285 6.995C9.11285 6.87133 9.13735 6.74889 9.18495 6.63475C9.23255 6.52061 9.30229 6.41703 9.39016 6.33C9.76016 5.96 10.3502 5.96 10.7202 6.33L11.3102 6.92L13.3202 5.07C13.7002 4.72 14.2902 4.74 14.6402 5.12C14.9902 5.51 14.9702 6.11 14.5902 6.46Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Invite

import React, { useState } from 'react'
import './Card.scss'
import { IGroupCourse } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { DAYS } from '../../individual-lessions/components/LessonsFilter'
import RequestModal from '../../../../components/request-modal'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

interface IProps {
  item: IGroupCourse
}

const CroupCard = (props: IProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { mutate, isSuccess, error } = useCustomMutation('student/groups/request', 'POST')

  return (
    <div className='card'>
      <p className='card__item'>
        <strong>{t('Star date')}:</strong> {props.item?.start_date} ({' '}
        {t(DAYS?.find(day => day?.id === moment(props.item?.start_date)?.get('weekday'))?.name || '')} )
      </p>
      <p className='card__item'>
        <strong>{t('End date')}:</strong> {props.item?.end_date} ({' '}
        {t(DAYS?.find(day => day?.id === moment(props.item?.end_date)?.get('weekday'))?.name || '')} )
      </p>
      <p className='card__item'>
        <strong>{t('School days')}:</strong> {''}
      </p>
      <p className='card__item'>
        <strong>{t('Time')}:</strong> {props.item?.time}
      </p>
      <p className='card__item card__deadline'>
        <strong>{t('Registration deadline')}:</strong> Yanv. 18, 2023 (Chorshanba)
      </p>
      <button className='card__button' onClick={() => setOpen(true)}>
        {t('Book a lesson')}
      </button>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title='Request group title'
        error={error}
        isSuccess={isSuccess}
        description={
          <>
            <p>{`${props?.item?.teacher?.firstname} ${props?.item?.teacher?.lastname}`}</p>
            <p>Nov 8(Tue) 09:30-09:55</p>
          </>
        }
        send={() =>
          mutate({
            group_id: props.item?.id
          })
        }
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle='Request group success title'
        successDesc='Request group success desc'
        errorTitle='Request group success title'
      />
    </div>
  )
}

export default CroupCard

import { useParams } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import TeacherSubject from './components/TeacherSubject'
import TeacherInfo from './components/TeacherInfo'
import { ISubject } from '../../../types/types'

export const TeachersSingle = () => {
  const { id } = useParams()
  return (
    <GetContainer url={'/student/teachers/' + id}>
      {({ data }) => (
        <div className='teachers-single__wrap'>
          {data?.data?.subjects?.map((item: ISubject) => (
            <TeacherSubject key={item?.slug} item={item} teacherId={id || ''} />
          ))}
          <TeacherInfo item={data?.data} />
        </div>
      )}
    </GetContainer>
  )
}

import React, { Fragment, ReactNode, useState } from 'react'
import Menu from '../../assets/img/icons/Menu'
import MobileMenu from './components/mobile-menu'
import SideMenu from './components/side-menu'
import { Outlet } from 'react-router-dom'

export interface IMenu {
  icon: ReactNode
  title: string
  path: string
}

interface IFirstLayout {
  menuItems: IMenu[]
  children?: React.ReactNode
}

function FirstLayout(props: IFirstLayout) {
  const [open, setOpen] = useState(false)
  return (
    <Fragment>
      {/* MOBILE-MENU */}
      <MobileMenu open={open} close={() => setOpen(false)} />
      {/* SIDEBAR */}
      <aside className='sidebar'>
        {/* SIDEBAR-MOBILE MENU*/}
        <div className='sidebar-mobile'>
          <Menu onClick={() => setOpen(true)} />
        </div>
        {/* SIDEMENU*/}
        <SideMenu data={props.menuItems} />
      </aside>
      <Outlet />
    </Fragment>
  )
}

export default FirstLayout

import React, { ReactNode } from 'react'

interface IProfileInfoCard {
    children: ReactNode
}

function ProfileInfoCard(props: IProfileInfoCard) {
  return (
    <div className="profile-menu__card">
        {props.children}
    </div>
  )
}

export default ProfileInfoCard
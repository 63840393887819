import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react'

type RandomContextType = {
  random: number
  setRandom: Dispatch<SetStateAction<number>>
  firebaseRandom: number
  setFirebaseRandom: Dispatch<SetStateAction<number>>
}
const defaultValue = {
  random: Math.random(),
  firebaseRandom: Math.random(),
  setRandom: () => 0,
  setFirebaseRandom: () => 0
}

export const RandomContext = createContext<RandomContextType>(defaultValue)

export const RandomProvider = ({ children }: { children: ReactNode }) => {
  const [random, setRandom] = useState(Math.random())
  const [firebaseRandom, setFirebaseRandom] = useState(Math.random())

  return (
    <RandomContext.Provider value={{ random, setRandom, firebaseRandom, setFirebaseRandom }}>
      {children}
    </RandomContext.Provider>
  )
}

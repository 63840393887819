import React, { SVGProps } from 'react'

function NewList(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.28906 6.29C7.86906 6.29 7.53906 5.95 7.53906 5.54V2.75C7.53906 2.55109 7.61808 2.36032 7.75873 2.21967C7.89938 2.07902 8.09015 2 8.28906 2C8.48797 2 8.67874 2.07902 8.81939 2.21967C8.96004 2.36032 9.03906 2.55109 9.03906 2.75V5.53C9.03906 5.95 8.70906 6.29 8.28906 6.29ZM15.7091 6.29C15.2891 6.29 14.9591 5.95 14.9591 5.54V2.75C14.9591 2.55109 15.0381 2.36032 15.1787 2.21967C15.3194 2.07902 15.5102 2 15.7091 2C15.908 2 16.0987 2.07902 16.2394 2.21967C16.38 2.36032 16.4591 2.55109 16.4591 2.75V5.53C16.4591 5.95 16.1291 6.29 15.7091 6.29Z'
        fill='currentColor'
      />
      <path
        d='M19.57 4.5C18.91 4.01 17.96 4.48 17.96 5.31V5.41C17.96 6.58 17.12 7.66 15.95 7.78C14.6 7.92 13.46 6.86 13.46 5.54V4.5C13.46 3.95 13.01 3.5 12.46 3.5H11.54C10.99 3.5 10.54 3.95 10.54 4.5V5.54C10.54 6.33 10.13 7.03 9.51 7.42C9.42 7.48 9.32 7.53 9.22 7.58C9.13 7.63 9.03 7.67 8.92 7.7C8.8 7.74 8.67 7.77 8.53 7.78C8.37 7.8 8.21 7.8 8.05 7.78C7.91 7.77 7.78 7.74 7.66 7.7C7.45271 7.63094 7.25458 7.53691 7.07 7.42C6.44 6.98 6.04 6.22 6.04 5.41V5.31C6.04 4.54 5.22 4.08 4.57 4.41C4.56 4.42 4.55 4.42 4.54 4.43C4.5 4.45 4.47 4.47 4.43 4.5C4.4 4.53 4.36 4.55 4.33 4.58C4.05 4.8 3.8 5.05 3.59 5.32C3.48 5.44 3.39 5.57 3.31 5.7C3.20842 5.84624 3.11816 6.00002 3.04 6.16C3.02 6.18 3.01 6.19 3.01 6.21C2.95 6.33 2.89 6.45 2.85 6.58C2.82 6.63 2.81 6.67 2.79 6.72C2.73 6.87 2.69 7.02 2.65 7.17C2.61 7.31 2.58 7.46 2.56 7.61C2.52364 7.86178 2.5036 8.11564 2.5 8.37V17.13C2.5 18.4216 3.01309 19.6603 3.92639 20.5736C4.83969 21.4869 6.0784 22 7.37 22H16.63C17.2695 22 17.9028 21.874 18.4937 21.6293C19.0845 21.3846 19.6214 21.0258 20.0736 20.5736C20.5258 20.1214 20.8846 19.5845 21.1293 18.9937C21.374 18.4028 21.5 17.7695 21.5 17.13V8.37C21.5 6.78 20.74 5.39 19.57 4.5ZM12 16.25H10.31V18C10.31 18.1989 10.231 18.3897 10.0903 18.5303C9.94968 18.671 9.75891 18.75 9.56 18.75C9.36109 18.75 9.17032 18.671 9.02967 18.5303C8.88902 18.3897 8.81 18.1989 8.81 18V16.25H7C6.59 16.25 6.25 15.91 6.25 15.5C6.25 15.09 6.59 14.75 7 14.75H8.81V13C8.81 12.8011 8.88902 12.6103 9.02967 12.4697C9.17032 12.329 9.36109 12.25 9.56 12.25C9.75891 12.25 9.94968 12.329 10.0903 12.4697C10.231 12.6103 10.31 12.8011 10.31 13V14.75H12C12.41 14.75 12.75 15.09 12.75 15.5C12.75 15.91 12.41 16.25 12 16.25Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default NewList

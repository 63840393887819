import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import WritingLessonCard from './components/WritingLessonCard'
import { IWritingLesson } from '../../../types/types'

export const LibraryNav = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='live-main'>
      <ul className='live-head'>
        <li onClick={() => navigate('/student/home/live-lessons')}>{t('Todays program')}</li>
        <li className='current' onClick={() => navigate('/student/home/library')}>
          {t('Writing lessons')}
        </li>
      </ul>
      <div className='library-list'>
        <GetContainer url='student/files'>
          {({ data }) => data?.data?.map((item: IWritingLesson) => <WritingLessonCard item={item} key={item.id} />)}
        </GetContainer>
      </div>
    </div>
  )
}

import React, { ReactNode } from 'react'
import QueryProvider from './QueryProvider'
import { AuthProvider } from '../context/AuthContext'
import { RandomProvider } from './RandomProvider'

interface IProps {
  children: ReactNode
}
const Providers = (props: IProps) => {
  return (
    <RandomProvider>
      <QueryProvider>
        <AuthProvider>{props.children}</AuthProvider>
      </QueryProvider>
    </RandomProvider>
  )
}

export default Providers

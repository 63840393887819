import { useTranslation } from 'react-i18next'
import MyCalendar from './component/bigCalendar'
import GetContainer from '../../../components/get-container'
import moment from 'moment'
import { ISchedule, ISlot } from '../../../types/types'
import { MEDIA_URL } from '../../../configs/request'
import Timer from './component/timer/Timer'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useContext, useState } from 'react'
import { RandomContext } from '../../../providers/RandomProvider'
import RequestModal from '../../../components/request-modal'

export const Calendar = () => {
  const { t, i18n } = useTranslation()
  const { mutate: enter, error, isSuccess } = useCustomMutation('/student/slots/enter', 'POST')
  const {
    mutate: cancel,
    error: cancelError,
    isSuccess: successCancel
  } = useCustomMutation('/student/slots/cancel', 'POST')
  const { setRandom, random } = useContext(RandomContext)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  return (
    <>
      <div className='courses-waiting'>
        <div className='calendar-title'>{t('Pending courses')}</div>
        <div className='history-table'>
          <div className='history-table__head'>
            <div>{t('Start date')}</div>
            <div>{t('Subject')}</div>
            <div>{t('Teacher')}</div>
            <div>{t('End date')}</div>
            <div></div>
          </div>
          <GetContainer url='/student/groups/request'>
            {({ data }) =>
              data?.data?.map((item: ISchedule) => (
                <div className='history-table__row'>
                  <div className='history-table__date'>{item.group?.start_date}</div>
                  <div className='course-type'>
                    <span className='course-type__ico'>
                      <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <span>
                      {item?.group?.subject?.name?.[i18n.language]}
                      <p>{item?.group?.subject?.title?.[i18n.language]}</p>
                    </span>
                  </div>
                  <div className='history-table__teacher'>
                    <img
                      src={
                        item?.group?.teacher?.photo
                          ? MEDIA_URL + item?.group?.teacher?.photo
                          : require('../../../assets/img/mock/student.jpg')
                      }
                      alt='name'
                    />
                    <span>{`${item?.group?.teacher?.firstname} ${item?.group?.teacher?.lastname}`}</span>
                  </div>
                  <div className='history-table__work'>{item?.group?.end_date}</div>
                  <div className='history-table__waiting'>{t('Waiting')}</div>
                  {/* <a href='#' className='history-table__link' /> */}
                </div>
              ))
            }
          </GetContainer>
        </div>
      </div>
      <div className='courses-today'>
        <div className='calendar-title'>{t(`Today's courses`)}</div>
        <div className='history-table'>
          <div className='history-table__head'>
            <div></div>
            <div>{t('Date/ Time')}</div>
            <div>{t('Lesson')}</div>
            <div>{t('Type')}</div>
            <div>{t('Teacher')}</div>
            <div></div>
          </div>
          <GetContainer
            url={'/student/slots'}
            params={{ status: 'ready_for_lesson,lesson_is_underway', date: moment().format('YYYY-MM-DD'), random }}
          >
            {({ data }) =>
              data?.data?.map((item: ISlot) => (
                <div className='history-table__row'>
                  <Timer time={item?.time} />

                  <div className='history-table__date'>
                    {item?.time} {item?.date}
                    {/* <span>11:30-11:55+05</span> */}
                  </div>
                  <div className='course-type'>
                    <span className='course-type__ico'>
                      <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <span>
                      {item?.schedule?.lesson?.name?.[i18n.language]}
                      {/* <p>{item?.schedule?.group?.lesson?.title?.[i18n.language]}</p> */}
                    </span>
                  </div>
                  <div className='history-table__type'>{t(item?.schedule?.group?.type)}</div>
                  <div className='history-table__teacher'>
                    <span>{`${item?.schedule?.group?.teacher?.firstname} ${item?.schedule?.group?.teacher?.lastname}`}</span>
                  </div>
                  <div className='history-table__btns'>
                    {item?.schedule?.group?.type === 'individual' && (
                      <button
                        className='btn btn-transparent'
                        onClick={() =>
                          cancel(
                            {
                              schedule_id: item?.schedule_id
                            },
                            {
                              onSuccess(data, variables, context) {
                                setRandom(Math.random())
                                setOpen(true)
                                setName('enter')
                              },
                              onError: () => {
                                setOpen(true)
                                setName('enter')
                              }
                            }
                          )
                        }
                      >
                        {t('Cancel')}
                      </button>
                    )}
                    <button
                      className='btn'
                      onClick={() =>
                        enter(
                          { slot_id: item.id },
                          {
                            onSuccess(data, variables, context) {
                              console.log(data)
                              if (data?.data?.zoom_link) {
                                window.open(data?.data?.zoom_link, '_blank')
                              }
                              setRandom(Math.random())
                              setOpen(true)
                              setName('enter')
                            },
                            onError: () => {
                              setOpen(true)
                              setName('enter')
                            }
                          }
                        )
                      }
                    >
                      {t('Go to class')}
                    </button>
                  </div>
                </div>
              ))
            }
          </GetContainer>
        </div>
      </div>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title={`Request ${name} title`}
        error={error || cancelError}
        isSuccess={isSuccess || successCancel}
        description={<div />}
        send={() => {}}
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle={`Request ${name} success title`}
        successDesc={`Request ${name} success desc`}
        errorTitle={`Request ${name} error title`}
      />
      <MyCalendar />
    </>
  )
}

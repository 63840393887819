import React from 'react'
import ProfileMenuItem, { IProfileMenuItem } from '../profile-menu-item'

interface IProfileMenuList {
  data: IProfileMenuItem[]
  parent?: string
}

function ProfileMenuList(props: IProfileMenuList) {
  return (
    <ul className='profile-menu__list'>
      {props.data.map(profile => {
        return <ProfileMenuItem {...profile} key={profile.path} parent={props.parent} />
      })}
    </ul>
  )
}

export default ProfileMenuList

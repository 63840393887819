import React from 'react'
import { IReview } from '../../../../types/types'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'

interface IProps {
  item: IReview
  count: number
}

function ReviewCard({ item, count }: IProps) {
  const { t } = useTranslation()
  return (
    <div className='reviews-list__item'>
      <div className='reviews-list__wrap'>
        <div className='course-type'>
          <span className='course-type__ico'>
            <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
          <span>{item?.student_schedule?.schedule?.group?.subject?.name?.['ru']}</span>
        </div>
        <div className='reviews-list__count'>
          {count} {t('review')}
        </div>
      </div>
      <div className='reviews-list__teacher'>
        <div>{item?.teacher?.firstname + ' ' + item?.teacher?.lastname}</div>
        <img
          src={
            item?.teacher?.photo ? MEDIA_URL + item?.teacher?.photo : require('../../../../assets/img/mock/student.jpg')
          }
          alt='student'
        />
        {/* <span>+2</span> */}
      </div>
      <a
        href={'/student/home/teacher-reviews/' + item?.student_schedule?.schedule?.group?.subject?.id}
        className='reviews-list__link'
      />
    </div>
  )
}

export default ReviewCard

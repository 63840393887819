import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CategoryInput = {
  name: string
  label: string
  placeholder: string
  control: Control
  disabled?: boolean
  error: any
  inputProps?: any
}

const CustomInput: React.FC<CategoryInput> = ({ name, label, placeholder, control, error, disabled }) => {
  const { t } = useTranslation()
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__item'>
          <div className='login-form__name'>{t(label)}</div>
          <div className='login-form__input'>
            <input
              type='text'
              disabled={disabled}
              placeholder={t(placeholder)}
              value={field.value}
              onChange={e => field.onChange(e.target.value)}
            />
          </div>
          {error?.message && <div className='login-form__advice'>{t(String(error?.message))}</div>}
        </div>
      )}
    />
  )
}

export default CustomInput

//@ts-nocheck
import React, { Fragment, useState } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import Calendar from 'react-calendar'
import GetContainer from '../../../../../components/get-container'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import toast from 'react-hot-toast'
import RequestModal from '../../../../../components/request-modal'
import { ISubject } from '../../../../../types/types'

interface TeacherCalendarProps {
  teacherId: string
  subject?: ISubject
}

const TeacherCalendar: React.FC<TeacherCalendarProps> = ({ teacherId, subject }) => {
  const { i18n, t } = useTranslation()
  const [month, setMonth] = useState(new Date())
  const [day, setDay] = useState(new Date())
  const [slot, setSlot] = useState(null)
  const [open, setOpen] = useState(false)

  const { mutate, isSuccess, error } = useCustomMutation('/student/slots/reserve', 'POST')

  return (
    <GetContainer
      hideLoading
      url={`/student/teachers/${teacherId}/monthly/slot/?date=${moment(month).format('YYYY-MM')}`}
    >
      {({ data }) => (
        <Fragment>
          <a data-tooltip-id='my-tooltip'>
            <Calendar
              onChange={selected => {
                if (data?.data?.dates?.[moment(selected).format('YYYY-MM-DD')] !== undefined) {
                  console.log(selected)
                  setDay(selected)
                  setOpen(true)
                } else {
                  setOpen(false)
                }
              }}
              onActiveStartDateChange={({ action, activeStartDate, value, view }) => setMonth(activeStartDate)}
              locale={i18n.language}
              tileClassName={({ activeStartDate, date, view }) =>
                view === 'month' && data?.data?.dates?.[moment(date).format('YYYY-MM-DD')] === true
                  ? 'greenDay'
                  : data?.dates?.[moment(date).format('YYYY-MM-DD')] === false
                  ? 'redDay'
                  : null
              }
            />
          </a>
          {!!subject && (
            <Tooltip
              id='my-tooltip'
              style={{
                backgroundColor: 'white',
                color: 'black',
                boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
                zIndex: 999
              }}
              place='bottom'
              clickable={true}
              isOpen={open}
              openOnClick={true}
              // isOpen={open}
              render={({ content, activeAnchor }) => {
                return (
                  <GetContainer
                    url={`/student/teachers/${teacherId}/daily/slot/?date=${moment(day).format('YYYY-MM-DD')}`}
                  >
                    {({ data: slots }) => (
                      <div className='teachers-schedule' style={{ display: 'block', position: 'relative' }}>
                        <div className='teachers-schedule__day'>{moment(day)?.format('ddd, MMM D, YYYY')}</div>
                        <div className='teachers-schedule__desc'>{t('Select a timeslot to book a class')}</div>
                        <ul className='teachers-schedule__list'>
                          {slots?.data?.map(slot => (
                            <li className={slot?.schedule_id ? 'unavailable' : 'available'} key={slot?.id}>
                              <span>{slot?.time}</span>
                              <span>{slot?.schedule_id ? t('unavailable') : t('available')}</span>
                              {!slot?.schedule_id && (
                                <button className='btn btn-transparent' onClick={() => setSlot(slot)}>
                                  {t('Book Class')}
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </GetContainer>
                )
              }}
            />
          )}
          <RequestModal
            isOpen={!!slot}
            close={() => setSlot(null)}
            title='Request individual title'
            error={error}
            isSuccess={isSuccess}
            description={
              <>
                <p>{subject?.name?.[i18n.language]}</p>
                <p>
                  {moment(day)?.format('ddd, MMM D, YYYY')} {slot?.time}
                </p>
              </>
            }
            send={() =>
              mutate({
                subject_id: subject?.id,
                slot_id: slot?.id
              })
            }
            acceptBtnText='Yes, I will'
            cancelBtnText='No'
            successTitle='Request individual success title'
            successDesc='Request individual success desc'
            errorTitle='Request individual success title'
          />
        </Fragment>
      )}
    </GetContainer>
  )
}

export default TeacherCalendar

import React, { Fragment, useContext, useState } from 'react'
import GetContainer from '../../../../components/get-container'
import { ISlot } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'
import { RandomContext } from '../../../../providers/RandomProvider'

function ComingClasses() {
  const { t, i18n } = useTranslation()
  const [date, setDate] = useState(new Date())
  const { mutate } = useCustomMutation('teacher/slots/enter', 'post')
  const { random, setRandom }: any = useContext(RandomContext)
  return (
    <div className='classes-coming'>
      <div className='classes-coming__carousel'>
        <GetContainer
          url='teacher/slots'
          params={{ status: 'ready_for_lesson', date: moment().format('YYYY-MM-DD'), random }}
        >
          {({ data }) =>
            data?.data?.map((item: ISlot) => (
              <div className='classes-big' key={item.id}>
                <div className='classes-big__name'>
                  {item?.schedule?.group?.subject?.name?.[i18n.language]}{' '}
                  <span>
                    {item?.schedule?.lesson?.name?.[i18n.language]} {item?.schedule?.date}
                  </span>
                </div>
                <div className='classes-big__card'>
                  {item?.schedule?.group?.students?.map(student => (
                    <Fragment>
                      <div className='classes-big__student'>{`${student?.firstname} ${student?.lastname}`}</div>
                      <div className='classes-big__age'>{student.birthday}</div>
                      <div className='classes-big__wrap'>
                        <div className='classes-big__desc'>{student.info?.for_teacher}</div>
                      </div>
                    </Fragment>
                  ))}
                  <div className='classes-big__btns'>
                    <a href={`/teacher/classes/${item.schedule_id}`} className='btn btn-green'>
                      {t('Lesson plan')}
                    </a>
                    <button
                      className='btn'
                      onClick={() =>
                        mutate(
                          { slot_id: item.id },
                          {
                            onSuccess(data, variables, context) {
                              console.log(data)
                              if (data?.data?.zoom_link) {
                                window.open(data?.data?.zoom_link, '_blank')
                              }
                              setRandom(Math.random())
                            }
                          }
                        )
                      }
                    >
                      {t('Enter')}
                    </button>
                  </div>
                </div>
              </div>
            ))
          }
        </GetContainer>
      </div>
      <div className='classes-coming__arrows'>
        <div className='classes-coming__prev'>
          <div
            className='arrow-left'
            onClick={() => setDate(new Date(moment(date).clone().subtract(1, 'days').format('YYYY-MM-DD')))}
          >
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14 16L10 12L14 8'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <span>{t(moment(date).clone().subtract(1, 'days').format('ddd'))}</span>
        </div>
        <div className='classes-coming__date'>
          {moment(date).format('MMMM YYYY (YYYY-MM-DD)')}
          <span>{t(moment(date)?.format('ddd'))}</span>
        </div>
        <div
          className='classes-coming__next'
          onClick={() => setDate(new Date(moment(date).clone().add(1, 'days').format('YYYY-MM-DD')))}
        >
          <span>{t(moment(date).clone().add(1, 'days').format('ddd'))}</span>
          <div className='arrow-right'>
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 16L14 12L10 8'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      </div>

      <div className='history-table'>
        <GetContainer
          url='teacher/slots'
          params={{ status: 'ready_for_lesson', date: moment(date).format('YYYY-MM-DD'), random }}
        >
          {({ data }) =>
            data?.data?.map((item: ISlot) => (
              <div className='history-table__row' key={item.id + item.date}>
                <div className='history-table__date'>
                  {item?.schedule?.group?.subject?.name?.[i18n.language]}{' '}
                  <span>{item?.schedule?.lesson?.name?.[i18n.language]}</span>
                </div>
                <div className='history-table__programm'>
                  {item?.schedule?.group?.students?.map(student => (
                    <a
                      href={'/teacher/students/' + student?.uuid}
                      key={student?.uuid}
                    >{`${student?.firstname} ${student?.lastname}`}</a>
                  ))}
                </div>
                <div className='history-table__btns'>
                  <a href={`/teacher/classes/${item.schedule_id}`} className='btn btn-white'>
                    {t('Lesson plan')}
                  </a>
                </div>
              </div>
            ))
          }
        </GetContainer>
      </div>
    </div>
  )
}

export default ComingClasses

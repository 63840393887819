import React from 'react'
import { ITeacher } from '../../../../types/types'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'

interface IProps {
  item: ITeacher
}

function TeacherInfo(props: IProps) {
  const { t } = useTranslation()
  return (
    <div className='teachers-info'>
      <div className='teachers-item__img'>
        <img
          src={props.item?.photo ? MEDIA_URL + props.item?.photo : require('../../../../assets/img/mock/teacher.jpg')}
          alt='img'
        />
      </div>
      <div className='teachers-info__wrap'>
        <div className='teachers-item__name'>{`${props.item?.firstname} ${props.item?.lastname}`}</div>
        <div className='teachers-item__pos'>{t(props.item?.role)}</div>
        <div className='teachers-info__title'>{t('Bio')}</div>
        <div className='teachers-info__text'>{props.item?.info?.bio}</div>
        {/* <div className='teachers-info__title'>Message</div>
        <div className='teachers-info__text'>
          Hello, I look forward to meeting you! I promise to keep our classes together fun, I even have some pokemon
          buddies. Take care and hopefully, we will meet soon!
        </div> */}
      </div>
    </div>
  )
}

export default TeacherInfo

import React from 'react'
import SideMenuItem from '../side-menu-item'
import { IMenu } from '../..'

export interface ISideMenu {
  data: IMenu[]
}

function SideMenu(props: ISideMenu) {
  return (
    <nav className='sidemenu'>
      <a href='#' className='sidemenu__logo'>
        <img src={require('../../../../assets/img/logo.png')} alt='Onschool' title='Onschool' />
      </a>
      <ul className='sidemenu__menu'>
        {props.data.map(item => (
          <SideMenuItem {...item} key={item.path} />
        ))}
      </ul>
    </nav>
  )
}

export default SideMenu

import React, { ReactNode } from 'react'

interface TeachersWrapperProps {
    children: React.ReactNode
}
const TeachersWrapper:React.FC<TeachersWrapperProps> = ({children}) => {
  return (
    <ul className='teachers-list'>
        {children}
    </ul>
  )
}

export default TeachersWrapper;
import { useState } from 'react'
import ComingClasses from './components/ComingClasses'
import CompleteClasses from './components/CompleteClasses'
import { useTranslation } from 'react-i18next'

export const Classes = () => {
  const [type, setType] = useState<'complete' | 'coming'>('complete')
  const { t } = useTranslation()
  return (
    <>
      <div className='classes-head'>
        <li className={type === 'complete' ? 'current' : ''} onClick={() => setType('complete')}>
          {t('Complete Class')}
        </li>
        <li className={type === 'coming' ? 'current' : ''} onClick={() => setType('coming')}>
          {t('Coming classes')}
        </li>
      </div>
      {type === 'complete' && <CompleteClasses />}
      {type === 'coming' && <ComingClasses />}
      {/* РАЗДЕЛЕНИЕ */}
    </>
  )
}

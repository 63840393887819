import React, { useState } from 'react'
import GetContainer from '../../get-container'
import Select from 'react-select'
import { Control, Controller } from 'react-hook-form'

interface IProps {
  url: string
  getLabel: (data: any) => any
  getValue: (data: any) => any
  params?: any
  name: string
  control: Control
  className?: string
  disabled?: boolean
}

function AsyncSelectController(props: IProps) {
  const [search, setSearch] = useState('')
  return (
    <GetContainer url={props.url} params={{ search: search, perPage: 100, ...(props.params && props?.params) }}>
      {({ data }) => (
        <Controller
          key={props.name}
          name={props.name}
          control={props.control}
          render={({ field }) => (
            <Select
              options={data?.data || []}
              getOptionLabel={props.getLabel}
              getOptionValue={props.getValue}
              inputValue={search}
              isDisabled={props.disabled}
              className={props.className || 'login-form__input'}
              onInputChange={e => setSearch(e)}
              value={data?.data?.find((item: any) => props?.getValue(item) === field.value)}
              onChange={e => field.onChange(e)}
              isClearable
            />
          )}
        />
      )}
    </GetContainer>
  )
}

export default AsyncSelectController

import React, { SVGProps } from 'react'

function Lock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        className='orange'
        d='M12.0011 17.3498C12.4334 17.3498 12.848 17.1781 13.1537 16.8724C13.4594 16.5667 13.6311 16.1521 13.6311 15.7198C13.6311 15.2875 13.4594 14.8729 13.1537 14.5673C12.848 14.2616 12.4334 14.0898 12.0011 14.0898C11.5688 14.0898 11.1542 14.2616 10.8485 14.5673C10.5428 14.8729 10.3711 15.2875 10.3711 15.7198C10.3711 16.1521 10.5428 16.5667 10.8485 16.8724C11.1542 17.1781 11.5688 17.3498 12.0011 17.3498Z'
        fill='currentColor'
      />
      <path
        d='M16.65 9.44H7.12V8.28C7.12 5.35 7.95 3.4 12 3.4C16.33 3.4 16.88 5.51 16.88 7.35C16.88 7.74 17.19 8.05 17.58 8.05C17.97 8.05 18.28 7.74 18.28 7.35C18.28 3.8 16.17 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 10.69 20.75 9.44 16.65 9.44ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C12.8001 12.7026 13.5668 13.0217 14.1326 13.5875C14.6983 14.1532 15.0174 14.9199 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Lock

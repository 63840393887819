import { Fragment, useState } from 'react'
import BasicInfo from './components/BasicInfo'
import ClassesHistory from './components/ClassesHistory'
import StudentsReservation from './components/StudentsReservation'
import StudentsNotes from './components/StudentsNotes'
import GetContainer from '../../../components/get-container'
import { useParams } from 'react-router-dom'
import { IStudent } from '../../../types/types'
import { MEDIA_URL } from '../../../configs/request'
import { useTranslation } from 'react-i18next'

export const Students = () => {
  const [type, setType] = useState<'basic' | 'classes' | 'reservation' | 'notes'>('basic')
  const { uuid } = useParams()
  const { t } = useTranslation()
  return (
    <GetContainer url={'/teacher/students/' + uuid}>
      {({ data }: { data: { data: IStudent } }) => (
        <Fragment>
          <div className='students-head'>
            <div className='students-info'>
              <div className='students-info__photo'>
                <img
                  src={
                    data?.data?.photo ? MEDIA_URL + data?.data?.photo : require('../../../assets/img/mock/student.jpg')
                  }
                  alt='student'
                />
              </div>
              <div className='students-info__wrap'>
                <div>{data?.data?.firstname}</div>
                <p>{data?.data?.role}</p>
              </div>
            </div>
            <ul className='students-menu'>
              <li className={type === 'basic' ? 'current' : ''} onClick={() => setType('basic')}>
                {t('Basic info')}
              </li>
              <li className={type === 'classes' ? 'current' : ''} onClick={() => setType('classes')}>
                {t('Class history')}
              </li>
              <li className={type === 'reservation' ? 'current' : ''} onClick={() => setType('reservation')}>
                {t('Reservation')}
              </li>
              <li className={type === 'notes' ? 'current' : ''} onClick={() => setType('notes')}>
                {t('Student notes')}
              </li>
            </ul>
          </div>
          <div className='students-tabs'>
            {type === 'basic' && <BasicInfo data={data?.data} />}
            {type === 'classes' && <ClassesHistory />}
            {type === 'reservation' && <StudentsReservation />}
            {type === 'notes' && <StudentsNotes />}
          </div>
        </Fragment>
      )}
    </GetContainer>
  )
}

import React, { SVGProps } from 'react'

function Library(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={25} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.5 16.6318V19.1318C20.5 21.0618 18.93 22.6318 17 22.6318H7C5.07 22.6318 3.5 21.0618 3.5 19.1318V18.4818C3.5 16.9118 4.78 15.6318 6.35 15.6318H19.5C20.05 15.6318 20.5 16.0818 20.5 16.6318ZM15.5 2.63184H8.5C4.5 2.63184 3.5 3.63184 3.5 7.63184V15.2118C4.26 14.5418 5.26 14.1318 6.35 14.1318H19.5C20.05 14.1318 20.5 13.6818 20.5 13.1318V7.63184C20.5 3.63184 19.5 2.63184 15.5 2.63184ZM13 11.3818H8C7.59 11.3818 7.25 11.0418 7.25 10.6318C7.25 10.2218 7.59 9.88184 8 9.88184H13C13.41 9.88184 13.75 10.2218 13.75 10.6318C13.75 11.0418 13.41 11.3818 13 11.3818ZM16 7.88184H8C7.59 7.88184 7.25 7.54184 7.25 7.13184C7.25 6.72184 7.59 6.38184 8 6.38184H16C16.41 6.38184 16.75 6.72184 16.75 7.13184C16.75 7.54184 16.41 7.88184 16 7.88184Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Library

import React, { SVGProps } from 'react'

function Settings(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M21.3 7.57969H15.72C15.33 7.57969 15.02 7.26969 15.02 6.87969C15.02 6.48969 15.33 6.17969 15.72 6.17969H21.3C21.69 6.17969 22 6.48969 22 6.87969C22 7.26969 21.69 7.57969 21.3 7.57969ZM6.42 7.57969H2.7C2.31 7.57969 2 7.26969 2 6.87969C2 6.48969 2.31 6.17969 2.7 6.17969H6.42C6.81 6.17969 7.12 6.48969 7.12 6.87969C7.12 7.26969 6.8 7.57969 6.42 7.57969Z'
        fill='currentColor'
      />
      <path
        d='M10.14 10.8297C10.6587 10.8297 11.1724 10.7275 11.6516 10.529C12.1308 10.3305 12.5663 10.0396 12.9331 9.67276C13.2999 9.30597 13.5908 8.87052 13.7893 8.39129C13.9878 7.91205 14.09 7.39841 14.09 6.87969C14.09 6.36097 13.9878 5.84732 13.7893 5.36809C13.5908 4.88885 13.2999 4.45341 12.9331 4.08662C12.5663 3.71982 12.1308 3.42887 11.6516 3.23036C11.1724 3.03186 10.6587 2.92969 10.14 2.92969C9.0924 2.92969 8.0877 3.34585 7.34693 4.08662C6.60616 4.82738 6.19 5.83208 6.19 6.87969C6.19 7.92729 6.60616 8.93199 7.34693 9.67276C8.0877 10.4135 9.0924 10.8297 10.14 10.8297ZM21.3 17.8097H17.58C17.19 17.8097 16.88 17.4997 16.88 17.1097C16.88 16.7197 17.19 16.4097 17.58 16.4097H21.3C21.69 16.4097 22 16.7197 22 17.1097C22 17.4997 21.69 17.8097 21.3 17.8097ZM8.28 17.8097H2.7C2.31 17.8097 2 17.4997 2 17.1097C2 16.7197 2.31 16.4097 2.7 16.4097H8.28C8.67 16.4097 8.98 16.7197 8.98 17.1097C8.98 17.4997 8.66 17.8097 8.28 17.8097Z'
        fill='currentColor'
      />
      <path
        className='orange'
        d='M13.8602 21.072C14.9078 21.072 15.9125 20.6558 16.6532 19.9151C17.394 19.1743 17.8102 18.1696 17.8102 17.122C17.8102 16.0744 17.394 15.0697 16.6532 14.3289C15.9125 13.5882 14.9078 13.172 13.8602 13.172C12.8126 13.172 11.8079 13.5882 11.0671 14.3289C10.3263 15.0697 9.91016 16.0744 9.91016 17.122C9.91016 18.1696 10.3263 19.1743 11.0671 19.9151C11.8079 20.6558 12.8126 21.072 13.8602 21.072Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Settings

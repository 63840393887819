import React, { useState } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CategoryInput = {
  name: string
  label: string
  placeholder: string
  control: Control
  error: any
}

const CustomPasswordInput: React.FC<CategoryInput> = ({ name, label, placeholder, control, error }) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__item'>
          <div className='login-form__name'>{t(label)}</div>
          <div className='login-form__input login-form__password'>
            <input
              type={showPassword ? 'text' : 'password'}
              value={field.value}
              placeholder={t(placeholder)}
              onChange={e => field.onChange(e.target.value)}
            />

            {!showPassword ? (
              <svg
                //   @ts-ignore
                onClick={() => setShowPassword(true)}
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.5819 12.0019C15.5819 13.9819 13.9819 15.5819 12.0019 15.5819C10.0219 15.5819 8.42188 13.9819 8.42188 12.0019C8.42188 10.0219 10.0219 8.42188 12.0019 8.42188C13.9819 8.42188 15.5819 10.0219 15.5819 12.0019Z'
                  stroke='#83878F'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12.0037 20.2688C15.5337 20.2688 18.8238 18.1887 21.1138 14.5887C22.0138 13.1787 22.0138 10.8087 21.1138 9.39875C18.8238 5.79875 15.5337 3.71875 12.0037 3.71875C8.47375 3.71875 5.18375 5.79875 2.89375 9.39875C1.99375 10.8087 1.99375 13.1787 2.89375 14.5887C5.18375 18.1887 8.47375 20.2688 12.0037 20.2688Z'
                  stroke='#83878F'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : (
              <svg
                //   @ts-ignore
                onClick={() => setShowPassword(false)}
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.00073 1L5.58989 4.58916M20.0007 19L16.4119 15.4112M12.8756 16.8246C12.2684 16.9398 11.6419 17 11.0012 17C6.52354 17 2.73324 14.0571 1.45898 9.99997C1.80588 8.8955 2.33924 7.87361 3.02217 6.97118M8.87941 7.87868C9.4223 7.33579 10.1723 7 11.0007 7C12.6576 7 14.0007 8.34315 14.0007 10C14.0007 10.8284 13.6649 11.5784 13.1221 12.1213M8.87941 7.87868L13.1221 12.1213M8.87941 7.87868L5.58989 4.58916M13.1221 12.1213L5.58989 4.58916M13.1221 12.1213L16.4119 15.4112M5.58989 4.58916C7.14971 3.58354 9.0073 3 11.0012 3C15.4788 3 19.2691 5.94291 20.5434 10C19.8365 12.2507 18.3553 14.1585 16.4119 15.4112'
                  stroke='#83878F'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            )}
          </div>
          {error?.message && <div className='login-form__advice'>{t(String(error?.message))}</div>}
        </div>
      )}
    />
  )
}

export default CustomPasswordInput

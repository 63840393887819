import React from 'react'
import { INotification } from '../../../../types/types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../configs/request'
interface IProps {
  item: INotification
}
function NotificationCard(props: IProps) {
  const { i18n } = useTranslation()
  return (
    <div>
      <li className={`messages-nots__item ${props.item?.read ? '' : 'new'}`}>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>{props?.item?.title?.[i18n.language]}</div>
          <div className='messages-nots__text'>{props?.item?.message?.[i18n.language]}</div>
          <div className='messages-nots__time'>
            <span>{moment(props.item?.created_at).format('YYYY-MM-DD')}</span>
            <span>{moment(props.item?.created_at).format('HH:mm')}</span>
          </div>
        </div>
        <div className='messages-nots__img'>
          <img src={MEDIA_URL + props.item?.photo} alt='Notification' />
        </div>
      </li>
    </div>
  )
}

export default NotificationCard

import React from 'react'
import GetContainerPagination from '../../../../components/get-container-pagination'
import { useParams } from 'react-router-dom'
import { IReport } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function StudentsNotes() {
  const { uuid } = useParams()
  const { t, i18n } = useTranslation()
  return (
    <div className='students-notes'>
      <h3 className='students__title'>{t('Student Notes')}</h3>
      <div className='students-notes__select'></div>
      <GetContainerPagination url={`/teacher/students/${uuid}/reviews`}>
        {({ data }: { data: { data: IReport[] } }) => (
          <ul className='students-notes__list'>
            {data?.data?.map(item => (
              <li className='students-notes__item' key={item?.id}>
                <h4 className='students-notes__title'>{`${item?.teacher?.firstname} ${item?.teacher?.lastname} ${t(
                  'at'
                )} ${moment(item?.created_at).format('YYYY-MM-DD HH:mm')}`}</h4>
                <ul className='students-notes__info'>
                  <li>
                    <span>{t('Today’s lesson name')}:</span> {item?.group_schedule?.lesson?.name?.[i18n.language]}
                  </li>
                  <li>
                    <span>{t('Message  ')}:</span> {item?.message}
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        )}
      </GetContainerPagination>
    </div>
  )
}

export default StudentsNotes

import { useState } from 'react'
import GetContainer from '../../../components/get-container'
import { useTranslation } from 'react-i18next'
import WithHTML from '../../../components/with-html'

export const HowToUse = () => {
  const [faqs, setFaqs] = useState([])
  const { i18n, t } = useTranslation()
  return (
    <div className='requirements-info'>
      <div className='support-content'>
        <h2 className='page-title'>{t('FAQ')}</h2>
      </div>
      <GetContainer
        url='student/faqs'
        params={{ type: 'how_to_use' }}
        onSuccess={data =>
          setFaqs(
            data?.data?.map((item: any) => {
              return {
                ...item,
                isActive: false
              }
            })
          )
        }
      >
        {({ data }) => (
          <ul className='support-quests'>
            {faqs?.map((item: any) => (
              <li className={`support-quest ${item.isActive ? 'active' : ''}`}>
                <div
                  className='support-quest__answer'
                  onClick={() =>
                    setFaqs((prev: any) =>
                      prev?.map((el: any) => {
                        if (el.id === item?.id) {
                          return { ...el, isActive: !el.isActive }
                        } else {
                          return el
                        }
                      })
                    )
                  }
                >
                  {item?.question?.[i18n.language]}
                  <span>
                    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7 10L12 15L17 10'
                        stroke='black'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </div>
                <div className='support-quest__quest'>
                  <WithHTML html={item?.answer?.[i18n.language]} />
                </div>
              </li>
            ))}
          </ul>
        )}
      </GetContainer>
    </div>
  )
}

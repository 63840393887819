import React from 'react'
import { IStudent } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

interface IProps {
  data: IStudent
}

function BasicInfo(props: IProps) {
  const { t, i18n } = useTranslation()
  return (
    <div className='students-basic'>
      <h3 className='students__title'>{t('Basic info')}</h3>
      <ul className='students-basic__list'>
        <li>
          <div className='students-basic__name'>{t('Id')}</div>
          <div className='students-basic__info'>{props?.data?.id}</div>
        </li>
        <li>
          <div className='students-basic__name'>{t('Name')}</div>
          <div className='students-basic__info'>{`${props?.data?.firstname} ${props?.data?.lastname}`}</div>
        </li>
        <li>
          <div className='students-basic__name'>{t('Gender')}</div>
          <div className='students-basic__info'>{t(props?.data?.gender)}</div>
        </li>
        <li>
          <div className='students-basic__name'>{t('Age')}</div>
          <div className='students-basic__info'>
            {moment().diff(props?.data?.birthday, 'years')} {t('years old')} (
            {moment(props?.data?.birthday).format('YYYY-MM-DD')})
          </div>
        </li>
        <li className='students-basic__program'>
          <div className='students-basic__name'>{t('Programs')}</div>
          <table className='students-basic__table'>
            <tbody>
              <tr>
                <th>{t('Program')}</th>
                <th>{t('Next Lesson')}</th>
                <th>{t('Previous Lesson')}</th>
              </tr>
              {props?.data?.progress?.map(progress => (
                <tr>
                  <td>{progress?.subject?.name?.[i18n.language]}</td>
                  <td>{progress?.next_lesson?.name?.[i18n.language]}</td>
                  <td>{progress?.last_lesson?.name?.[i18n.language]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </li>
      </ul>
    </div>
  )
}

export default BasicInfo

import { initializeApp } from 'firebase/app'

import { getMessaging } from 'firebase/messaging'

//Firebase Config values imported from .env file
export const firebaseConfig = {
  apiKey: 'AIzaSyAARCMCFrivX7ZZHj_1QZtFRrgVUphKMA4',
  authDomain: 'uniclass-33e3c.firebaseapp.com',
  projectId: 'uniclass-33e3c',
  storageBucket: 'uniclass-33e3c.firebasestorage.app',
  messagingSenderId: '124610729427',
  appId: '1:124610729427:web:dc95d1942c13ca922a8b26',
  measurementId: 'G-63EVZHB921'
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)

// Messaging service
export const messaging = getMessaging(firebaseApp)

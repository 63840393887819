import React, { SVGProps } from 'react'

function Payment(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.001 7C19.001 7.18 18.991 7.35 18.971 7.52C18.856 8.92652 18.2425 10.2457 17.241 11.24C16.6862 11.7998 16.0257 12.2437 15.2977 12.5459C14.5698 12.848 13.7891 13.0024 13.001 13H9.87097C9.37097 13 8.95097 13.37 8.88097 13.86L8.12097 19.14C8.06097 19.53 7.78097 19.85 7.41097 19.95C7.32097 19.99 7.23097 20 7.13097 20H5.18097C5.03577 20.0008 4.89214 19.9698 4.76013 19.9094C4.62811 19.8489 4.51088 19.7603 4.41661 19.6499C4.32235 19.5394 4.25332 19.4097 4.21435 19.2699C4.17538 19.13 4.1674 18.9833 4.19097 18.84L6.58097 4.51C6.69649 3.80853 7.05761 3.17097 7.59983 2.71118C8.14205 2.2514 8.83006 1.99933 9.54097 2H14.001C16.761 2 19.001 4.24 19.001 7Z'
        fill='currentColor'
      />
      <path
        className='orange'
        d='M20.9988 10.9997C20.9988 12.3797 20.4388 13.6297 19.5388 14.5397C18.6288 15.4397 17.3788 15.9997 15.9988 15.9997H13.8488C13.6108 15.999 13.3804 16.0832 13.1989 16.2372C13.0174 16.3911 12.8968 16.6048 12.8588 16.8397L12.1388 21.1597C12.1007 21.3946 11.9801 21.6082 11.7986 21.7622C11.6172 21.9161 11.3867 22.0004 11.1488 21.9997H8.21875C8.05096 22.0028 7.88535 21.9614 7.73875 21.8797C7.51875 21.7597 7.65875 21.4697 7.89875 21.3697C8.79875 21.0797 9.45875 20.3097 9.59875 19.3497L10.2388 14.9297C10.2788 14.6797 10.4888 14.4997 10.7288 14.4997H12.9988C15.0088 14.4997 16.8988 13.7197 18.2988 12.3097C19.1288 11.4897 19.7488 10.4797 20.1088 9.38967C20.1888 9.13967 20.5188 9.08967 20.6288 9.31967C20.8688 9.83967 20.9988 10.3997 20.9988 10.9997Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Payment

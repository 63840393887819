import { useTranslation } from 'react-i18next'
import GetContainer from '../../../components/get-container'
import GetContainerPagination from '../../../components/get-container-pagination'

export const Coin = () => {
  const { i18n, t } = useTranslation()
  return (
    <div className='coin-info'>
      <div className='coin__title'>
        Next charge date: <span>次回の課金日は 01月20日 です。</span>
      </div>
      <GetContainerPagination url='/student/coin_transactions'>
        {({ data }) => (
          <div className='coin-table table'>
            <table>
              <tbody>
                <tr className='head'>
                  <td>{t('Date')}</td>
                  <td>{t('Amount')}</td>
                  <td>{t('Description')}</td>
                </tr>
                {data?.data?.map((item: any) => (
                  <tr key={item?.id}>
                    <td>{item?.created_at}</td>
                    <td className={`bold ${item?.amount > 0 ? 'green' : 'red'}`}>{item?.amount}</td>
                    <td>{item?.comment?.[i18n.language]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </GetContainerPagination>
    </div>
  )
}

import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CategoryInput = {
  name: string
  label: string
  control: Control
  error: any
  data: { label: string; value: string }[]
}

const CustomRadio: React.FC<CategoryInput> = ({ name, label, control, error, data }) => {
  const { t } = useTranslation()

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__item'>
          <div className='login-form__name'>{t(label)}</div>
          <div className='filter-gender'>
            {data?.map(item => (
              <label htmlFor={item.value} key={item.value}>
                <input
                  type='radio'
                  name={name}
                  checked={field.value === item.value}
                  onChange={e => {
                    if (e.target.checked) {
                      field.onChange(item.value)
                    }
                  }}
                  id={item.value}
                />
                <span className='btn btn-white'>{t(item.label)}</span>
              </label>
            ))}
          </div>
          {error?.message && <div className='login-form__advice'>{t(String(error?.message))}</div>}
        </div>
      )}
    />
  )
}

export default CustomRadio

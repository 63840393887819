import React, { SVGProps } from 'react'

function List(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.9002 9.85012L21.4902 19.7401C21.5102 20.0101 21.3802 20.1901 21.3102 20.2701C21.2302 20.3601 21.0602 20.5001 20.7802 20.5001H18.0502L20.2102 9.85012H20.9002ZM22.0002 6.00012L21.9902 6.02012C22.0102 6.26012 21.9902 6.51012 21.9302 6.76012L14.5602 20.2901C14.4433 20.7794 14.1642 21.2147 13.7684 21.5252C13.3725 21.8357 12.8833 22.0031 12.3802 22.0001H20.7802C21.0853 22.001 21.3873 21.9387 21.6672 21.8172C21.947 21.6957 22.1987 21.5175 22.4063 21.2939C22.6139 21.0703 22.7729 20.8062 22.8734 20.5181C22.9739 20.2301 23.0137 19.9243 22.9902 19.6201L22.0002 6.00012ZM11.4502 2.24112C11.5502 1.84112 11.3002 1.43112 10.9002 1.33112C10.5002 1.24112 10.0902 1.48112 9.99023 1.88112L9.49023 3.95112H11.0302L11.4502 2.24112ZM18.0502 2.20912C18.1402 1.79912 17.8802 1.40912 17.4702 1.31912C17.3737 1.2986 17.2741 1.29734 17.177 1.31541C17.08 1.33347 16.9875 1.3705 16.9048 1.42439C16.8222 1.47827 16.7509 1.54794 16.6952 1.6294C16.6395 1.71086 16.6004 1.80252 16.5802 1.89912L16.1302 3.96912H17.6702L18.0502 2.20912Z'
        fill='currentColor'
      />
      <path
        d='M21.8198 5.33141C21.4898 4.53141 20.7098 3.96141 19.7498 3.96141H17.6698L17.1098 6.55141C17.0298 6.90141 16.7198 7.14141 16.3798 7.14141C16.3298 7.14141 16.2698 7.14141 16.2198 7.12141C16.0266 7.07705 15.8583 6.9595 15.7501 6.79349C15.6419 6.62749 15.6024 6.42599 15.6398 6.23141L16.1298 3.95141H11.0298L10.3998 6.55141C10.3198 6.89141 10.0098 7.12141 9.66975 7.12141C9.60975 7.12141 9.54976 7.11141 9.48975 7.10141C9.39376 7.07799 9.30335 7.03584 9.2237 6.97737C9.14404 6.91891 9.07673 6.84528 9.02562 6.76072C8.97452 6.67616 8.94062 6.58233 8.92588 6.48463C8.91115 6.38693 8.91586 6.28728 8.93975 6.19141L9.47975 3.94141H7.44975C6.46975 3.94141 5.59975 4.58141 5.30975 5.52141L1.09975 19.0714C0.659754 20.5214 1.72975 22.0014 3.23975 22.0014H16.3798C17.4198 22.0014 18.3198 21.3014 18.5598 20.2914L21.9298 6.76141C21.9898 6.51141 22.0098 6.26141 21.9898 6.02141C21.9747 5.7831 21.9172 5.54941 21.8198 5.33141ZM14.6998 16.7514H6.69975C6.28975 16.7514 5.94975 16.4114 5.94975 16.0014C5.94975 15.5914 6.28975 15.2514 6.69975 15.2514H14.6998C15.1098 15.2514 15.4498 15.5914 15.4498 16.0014C15.4498 16.4114 15.1098 16.7514 14.6998 16.7514ZM15.6998 12.7514H7.69975C7.28975 12.7514 6.94975 12.4114 6.94975 12.0014C6.94975 11.5914 7.28975 11.2514 7.69975 11.2514H15.6998C16.1098 11.2514 16.4498 11.5914 16.4498 12.0014C16.4498 12.4114 16.1098 12.7514 15.6998 12.7514Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default List

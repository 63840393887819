import React, { SVGProps } from "react";

function HomeWork(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
      {...props}
    >
      <path
        fill='currentColor'
        d='M13.035 17.324h.718a2.15 2.15 0 002.145-2.145v-.718h-2.863v2.863zm-4.3-2.145a2.15 2.15 0 002.144 2.145h.719v-2.863H8.734v.718zm0-2.863v.718h2.863v-2.863h-.719a2.15 2.15 0 00-2.145 2.145z'
      ></path>
      <path
        fill='currentColor'
        d='M20.557 7.002l-5.901-4.136C13.045 1.737 10.58 1.8 9.032 3L3.89 7.012c-1.037.8-1.837 2.443-1.837 3.736v7.082c0 2.617 2.124 4.752 4.731 4.752h11.053a4.742 4.742 0 004.732-4.742v-6.958c.01-1.386-.883-3.09-2.012-3.88zm-3.233 8.18a3.592 3.592 0 01-3.582 3.582H10.88a3.585 3.585 0 01-3.582-3.582v-2.864a3.592 3.592 0 013.582-3.581h2.864a3.585 3.585 0 013.581 3.582v2.863z'
      ></path>
      <path
        fill='currentColor'
        d='M13.752 10.17h-.719v2.864h2.864v-.718a2.15 2.15 0 00-2.145-2.145z'
      ></path>
    </svg>
  );
}

export default HomeWork;
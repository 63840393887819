import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { request } from './request'

i18n

  // Enables the i18next backend
  .use(Backend)

  // Enable automatic language detection
  .use(LanguageDetector)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'uz', 'ru'],
    fallbackLng: 'ru',
    compatibilityJSON: 'v3',
    lng: 'ru',
    backend: {
      /* translation file path */
      loadPath: 'words?lang={{lng}}',

      request: async function (options: any, url: string, payload: any, callback: any) {
        await request({
          url: url,
          //   headers: {'Accept-Language': 'ru'},
          method: 'GET'
        })
          .then(res => {
            callback(null, { status: 200, data: res.data })
          })
          .catch(err => {
            callback(err)
          })
      }
    },
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    }
  })

export default i18n

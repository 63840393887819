import React from 'react'

interface IProps {
  html: string
}

function WithHTML(props: IProps) {
  return <div className='ck-content' dangerouslySetInnerHTML={{ __html: props.html }}></div>
}

export default WithHTML

import React, { SVGProps } from 'react'
export {};
function CalendarNav(props: SVGProps<SVGSVGElement>) {
  return (
    <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75023V2C8.75023 1.59 8.41023 1.25 8.00023 1.25C7.59023 1.25 7.25023 1.59 7.25023 2V3.56C4.55023 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56ZM19.0002 15C16.7902 15 15.0002 16.79 15.0002 19C15.0002 19.75 15.2102 20.46 15.5802 21.06C15.9328 21.6525 16.4335 22.143 17.0332 22.4831C17.6329 22.8233 18.3108 23.0014 19.0002 23C20.4602 23 21.7302 22.22 22.4202 21.06C22.7902 20.46 23.0002 19.75 23.0002 19C23.0002 16.79 21.2102 15 19.0002 15ZM21.0702 18.57L18.9402 20.54C18.8002 20.67 18.6102 20.74 18.4302 20.74C18.2402 20.74 18.0502 20.67 17.9002 20.52L16.9102 19.53C16.7707 19.3889 16.6925 19.1984 16.6925 19C16.6925 18.8016 16.7707 18.6111 16.9102 18.47C17.2002 18.18 17.6802 18.18 17.9702 18.47L18.4502 18.95L20.0502 17.47C20.3502 17.19 20.8302 17.21 21.1102 17.51C21.3902 17.81 21.3702 18.28 21.0702 18.57Z" fill="currentColor"/>
    <path d="M20 9.83984H4C3.45 9.83984 3 10.2898 3 10.8398V16.9998C3 19.9998 4.5 21.9998 8 21.9998H12.93C13.62 21.9998 14.1 21.3298 13.88 20.6798C13.68 20.0998 13.51 19.4598 13.51 18.9998C13.51 15.9698 15.98 13.4998 19.01 13.4998C19.3 13.4998 19.59 13.5198 19.87 13.5698C20.47 13.6598 21.01 13.1898 21.01 12.5898V10.8498C21.0074 10.5828 20.9001 10.3274 20.7113 10.1385C20.5224 9.9497 20.2671 9.84245 20 9.83984ZM9.21 18.2098C9.02 18.3898 8.76 18.4998 8.5 18.4998C8.24 18.4998 7.98 18.3898 7.79 18.2098C7.61 18.0198 7.5 17.7598 7.5 17.4998C7.5 17.2398 7.61 16.9798 7.79 16.7898C7.89 16.6998 7.99 16.6298 8.12 16.5798C8.49 16.4198 8.93 16.5098 9.21 16.7898C9.39 16.9798 9.5 17.2398 9.5 17.4998C9.5 17.7598 9.39 18.0198 9.21 18.2098ZM9.21 14.7098L9.06 14.8298C9 14.8698 8.94 14.8998 8.88 14.9198C8.82 14.9498 8.76 14.9698 8.7 14.9798C8.63 14.9898 8.56 14.9998 8.5 14.9998C8.24 14.9998 7.98 14.8898 7.79 14.7098C7.61 14.5198 7.5 14.2598 7.5 13.9998C7.5 13.7398 7.61 13.4798 7.79 13.2898C8.02 13.0598 8.37 12.9498 8.7 13.0198C8.76 13.0298 8.82 13.0498 8.88 13.0798C8.94 13.0998 9 13.1298 9.06 13.1698L9.21 13.2898C9.39 13.4798 9.5 13.7398 9.5 13.9998C9.5 14.2598 9.39 14.5198 9.21 14.7098ZM12.71 14.7098C12.52 14.8898 12.26 14.9998 12 14.9998C11.74 14.9998 11.48 14.8898 11.29 14.7098C11.11 14.5198 11 14.2598 11 13.9998C11 13.7398 11.11 13.4798 11.29 13.2898C11.67 12.9198 12.34 12.9198 12.71 13.2898C12.89 13.4798 13 13.7398 13 13.9998C13 14.2598 12.89 14.5198 12.71 14.7098Z" fill="currentColor"/>
    </svg>
    </>
  )
}

export default CalendarNav

import React from "react";

function Balance() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      fill='none'
      viewBox='0 0 30 30'
    >
      <path
        fill='#fff'
        d='M15.938 18.15h.287c.412 0 .762-.375.762-.837 0-.588-.137-.663-.537-.8l-.512-.175v1.812z'
      ></path>
      <path
        fill='#fff'
        d='M15 2.5C8.1 2.5 2.5 8.1 2.5 15S8.1 27.5 15 27.5 27.5 21.9 27.5 15 21.9 2.5 15 2.5zM9.475 20.525a.943.943 0 010 1.325.927.927 0 01-.662.275.927.927 0 01-.663-.275A9.637 9.637 0 015.312 15 9.636 9.636 0 018.15 8.15a.943.943 0 011.325 0 .943.943 0 010 1.325A7.763 7.763 0 007.188 15c0 2.087.812 4.05 2.287 5.525zm7.588-5.8c1.2.425 1.787 1.263 1.787 2.575 0 1.5-1.175 2.712-2.638 2.712h-.287v.226a.944.944 0 01-.938.937.944.944 0 01-.937-.938v-.224h-.038c-1.587 0-2.887-1.338-2.887-2.976 0-.512.425-.937.938-.937.512 0 .937.425.937.938 0 .612.45 1.1 1.012 1.1h.038v-2.476l-1.125-.4c-1.2-.425-1.788-1.262-1.788-2.575 0-1.5 1.175-2.712 2.638-2.712h.287V9.75c0-.512.426-.938.938-.938s.938.426.938.938v.225h.037c1.588 0 2.888 1.338 2.888 2.975a.944.944 0 01-.938.938.944.944 0 01-.938-.938c0-.612-.45-1.1-1.012-1.1h-.037v2.475l1.124.4zm4.787 7.125a.927.927 0 01-.663.275.927.927 0 01-.662-.275.942.942 0 010-1.325A7.762 7.762 0 0022.813 15c0-2.088-.813-4.05-2.288-5.525a.942.942 0 010-1.325.943.943 0 011.325 0A9.635 9.635 0 0124.687 15a9.636 9.636 0 01-2.837 6.85z'
      ></path>
      <path
        fill='#fff'
        d='M13.025 12.7c0 .587.138.662.538.8l.512.175V11.85h-.287c-.425 0-.763.387-.763.85z'
      ></path>
    </svg>
  );
}

export default Balance;
import React from 'react'
import { useTimer } from 'react-timer-hook'
import moment from 'moment'

interface IProps {
  time: string
}

function Timer(props: IProps) {
  const time = moment(props.time, 'HH:mm:ss').toDate()
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: time,
    onExpire: () => console.warn('onExpire called')
  })

  return (
    <div className='history-table__count'>
      <div>
        {hours}:{minutes}:{seconds}
      </div>
    </div>
  )
}

export default Timer

import { useTranslation } from 'react-i18next'
import Form from '../../../components/form'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import CustomInput from '../../../components/form/custom-input'
import { useTimer } from 'react-timer-hook'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { Fragment, useContext } from 'react'
import auth from '../../../configs/auth'
import { AuthContext } from '../../../context/AuthContext'

const validationSchema = Yup.object().shape({
  code: Yup.string().required('This field is required')
})
const initialValues = {
  code: ''
}

const ResendButton = () => {
  const { t } = useTranslation()
  const time = new Date()
  time.setSeconds(time.getSeconds() + 120)
  const { seconds, minutes, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => console.warn('onExpire called')
  })
  const { refetch } = useCustomQuery(isRunning ? '' : 'verification/send_code')
  return (
    <Fragment>
      <span>{minutes}</span>:<span>{seconds}</span>
      <button
        className={`btn btn-orange ${isRunning ? 'disabled' : ''}`}
        disabled={isRunning}
        onClick={() => refetch()}
        style={{ padding: '0.5rem', paddingRight: '1rem', paddingLeft: '1rem', marginLeft: '1rem' }}
      >
        {t('Resend code')}
      </button>
    </Fragment>
  )
}

export const Login2 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setUser } = useContext(AuthContext)

  return (
    <>
      {/*  LOGIN	*/}
      <section className='login'>
        <div className='login__wrap'>
          <div className='login__logo'>
            <img src={require('../../../assets/img/logo.png')} alt='Onschool' />
          </div>
          <Form
            url='verification/check_code'
            initialValues={initialValues}
            validationSchema={validationSchema}
            method='post'
            onSuccess={async response => {
              setUser({ ...response.data.user })
              await window.localStorage.setItem('userData', JSON.stringify(response.data.user))
              if (response.data.user?.role === 'student') {
                await navigate('/student/home')
              } else {
                await navigate('/teacher/home')
                document.body.classList.add('teacher')
              }
            }}
          >
            {({ form, handleFinish }) => {
              const {
                control,
                formState: { errors },
                handleSubmit
              } = form
              return (
                <div className='login-form login-form__centered'>
                  <div className='login-form__title'>{t('Phone verification')}</div>
                  <CustomInput
                    control={control}
                    name='code'
                    label='SMS code'
                    placeholder='Write SMS code'
                    error={errors?.code}
                  />
                  <ResendButton />
                  <div className='login-form__submit'>
                    <button onClick={handleSubmit(data => handleFinish(data))} className='btn btn-orange'>
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
      </section>
    </>
  )
}

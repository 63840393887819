import GetContainer from '../../../components/get-container'
import { IReview } from '../../../types/types'
import ReviewCard from './components/ReviewCard'

export const TeacherReviews = () => {
  return (
    <div className='reviews-list'>
      <GetContainer url='/student/reviews/count'>
        {({ data: count }) => (
          <GetContainer url='/student/reviews'>
            {({ data }) =>
              data?.data?.map((item: IReview) => (
                <ReviewCard
                  count={
                    count?.find(
                      (countItem: any) => countItem?.subject_id === item?.student_schedule?.schedule?.group?.subject?.id
                    )?.count
                  }
                  item={item}
                  key={item?.id}
                />
              ))
            }
          </GetContainer>
        )}
      </GetContainer>
    </div>
  )
}

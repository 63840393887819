import { useQuery } from '@tanstack/react-query'
import { request } from '../configs/request'

export const useCustomQuery = (url: string, params?: any) => {
  return useQuery({
    queryKey: [url, ...Object.values(params || {})],
    enabled: !!url,
    queryFn: async () => {
      const response = await request({
        url: url,
        method: 'get'
      })
      return response.data
    }
  })
}

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}
const queryClient = new QueryClient()

const QueryProvider = (props: IProps) => {
  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
}

export default QueryProvider

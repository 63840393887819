import React, { SVGProps } from 'react'

function Box(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.25 12.75V22H7.81C4.17 22 2 19.83 2 16.19V12.75H11.25ZM22 7.81V11.25H12.75V2H16.19C19.83 2 22 4.17 22 7.81ZM11.25 2V11.25H2V7.81C2 4.17 4.17 2 7.81 2H11.25ZM22 12.75V16.19C22 19.83 19.83 22 16.19 22H12.75V12.75H22Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Box

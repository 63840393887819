import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CategoryInput = {
  name: string
  label: string
  control: Control
  error: any
}

const CustomCheckbox: React.FC<CategoryInput> = ({ name, label, control, error }) => {
  const { t } = useTranslation()

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__check'>
          <label htmlFor={name}>
            <input type='checkbox' id={name} checked={field.value} onChange={e => field.onChange(e.target.checked)} />
            <span style={{ ...(error?.message ? { color: 'red' } : {}) }}>{t(label)}</span>
          </label>
        </div>
      )}
    />
  )
}

export default CustomCheckbox

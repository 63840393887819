import { useMutation } from '@tanstack/react-query'
import { request } from '../configs/request'

export const useCustomMutation = (url: string, method: string) => {
  return useMutation({
    mutationKey: [url, method],
    mutationFn: async (data: any) => {
      const response = await request({
        url: url,
        method: method,
        data: data
      })
      return response.data
    }
  })
}

import SecondLayout from '../layouts/second-layout'
import { Coin } from '../pages/students-pages/coin'
import { NewCourses } from '../pages/students-pages/new-courses'
import { Home } from '../pages/students-pages/home'
import { HomeWork } from '../pages/students-pages/homework'
import { LessonHistory } from '../pages/students-pages/lesson-history'
import { Messages } from '../pages/students-pages/messages'
import { Notifications } from '../pages/students-pages/notifications'
import { TeacherReviews } from '../pages/students-pages/teacher-reviews'
import { TeacherReviewsSingle } from '../pages/students-pages/teacher-reviews-single'
import { Teachers } from '../pages/students-pages/teachers'
import {
  homeMenuData,
  profilesMenuData,
  schoolMenuData,
  sideMenuData,
  supportMenuData,
  teachersMenuData
} from './menuData'
import { Calendar } from '../pages/students-pages/calendar'
import { LiveLessons } from '../pages/students-pages/live-lessons'
import { LibraryNav } from '../pages/students-pages/library-nav'
import { Courses } from '../pages/students-pages/courses'
import { CourseSingle } from '../pages/students-pages/course-single'
import { IndividualLessons } from '../pages/students-pages/individual-lessions'
import { GroupCourses } from '../pages/students-pages/group-courses'
import { GroupCoursesSingle } from '../pages/students-pages/group-courses-single'
import { Requirements } from '../pages/students-pages/requirements'
import { HowToUse } from '../pages/students-pages/how-to-use'
import { AccountManual } from '../pages/students-pages/account-manual'
import { Faq } from '../pages/students-pages/faq'
import { Settings } from '../pages/students-pages/settings'
import { Payment } from '../pages/students-pages/payment'
import FirstLayout from '../layouts/first-layout'
import { Login } from '../pages/auth/login'
import { Login2 } from '../pages/auth/login-2'
import { Login3 } from '../pages/auth/login-3'
import ForgotPassword from '../pages/auth/forgot-password'
import { Classes } from '../pages/teachers-pages/classes'
import { TeacherHome } from '../pages/teachers-pages/teacher-home'
import { Students } from '../pages/teachers-pages/students'
import { TeacherStatistics } from '../pages/teachers-pages/teacher-statistics'
import { TeacherSettings } from '../pages/teachers-pages/teacher-settings'
import { LessonsPlan } from '../pages/teachers-pages/lessons-plan'
import { LessonsSingle } from '../pages/teachers-pages/lessons-single'
import { TeachersSingle } from '../pages/students-pages/teachers-single'
import { ClassesSingle } from '../pages/teachers-pages/classes-single'
import { Subjects } from '../pages/teachers-pages/subjects'
// import { Students } from '../pages/students-pages/students'
// 11
export const homeRouterData = [
  {
    path: '',
    element: <Home />
  },
  {
    path: 'lesson-history',
    element: <LessonHistory />
  },
  {
    path: 'teacher-reviews',
    element: <TeacherReviews />
  },
  {
    path: 'calendar',
    element: <Calendar />
  },
  {
    path: 'homework',
    element: <LessonHistory />
  },
  {
    path: 'homework/:id',
    element: <HomeWork />
  },
  {
    path: 'live-lessons',
    element: <LiveLessons />
  },
  {
    path: 'library',
    element: <LibraryNav />
  },
  {
    path: 'messages',
    element: <Messages />
  },
  {
    path: 'notifications',
    element: <Notifications />
  },
  {
    path: 'lesson-history/:id',
    element: <HomeWork />
  },
  {
    path: 'teacher-reviews/:id',
    element: <TeacherReviewsSingle />
  }
]
// 13
export const schoolRouterData = [
  {
    path: 'teachers',
    element: <Teachers />
  },
  {
    path: 'teachers/:id',
    element: <TeachersSingle />
  },
  {
    path: 'courses',
    element: <Courses />
  },
  {
    path: 'courses/:slug',
    element: <CourseSingle />
  },
  {
    path: 'individual-lessons',
    element: <IndividualLessons />
  },
  {
    path: 'group-courses',
    element: <GroupCourses />
  },
  {
    path: 'group-courses/:slug',
    element: <GroupCoursesSingle />
  },
  {
    path: 'new-courses',
    element: <NewCourses />
  },
  {
    path: 'calendar',
    element: <Calendar />
  },
  {
    path: 'homework',
    element: <LessonHistory />
  },
  {
    path: 'homework/:id',
    element: <HomeWork />
  },
  {
    path: 'live-lessons',
    element: <LiveLessons />
  },
  {
    path: 'library',
    element: <LibraryNav />
  },
  {
    path: 'messages',
    element: <Messages />
  },
  {
    path: 'notifications',
    element: <Notifications />
  }
]

export const supportRouterData = [
  {
    path: 'requirements',
    element: <Requirements />
  },
  {
    path: 'how-to-use',
    element: <HowToUse />
  },
  {
    path: 'account-manual',
    element: <AccountManual />
  },
  {
    path: 'faq',
    element: <Faq />
  },
  {
    path: 'calendar',
    element: <Calendar />
  },
  {
    path: 'homework',
    element: <LessonHistory />
  },
  {
    path: 'homework/:id',
    element: <HomeWork />
  },
  {
    path: 'live-lessons',
    element: <LiveLessons />
  },
  {
    path: 'library',
    element: <LibraryNav />
  },
  {
    path: 'live-lessons',
    element: <LiveLessons />
  },
  {
    path: 'library',
    element: <TeacherReviews />
  },
  {
    path: 'messages',
    element: <Messages />
  },
  {
    path: 'notifications',
    element: <Notifications />
  }
]

export const profilesRouterData = [
  {
    path: 'settings',
    element: <Settings />
  },
  {
    path: 'payment',
    element: <Payment />
  },
  {
    path: 'coin',
    element: <Coin />
  },
  {
    path: 'invite',
    element: <Settings />
  },
  {
    path: 'calendar',
    element: <Calendar />
  },
  {
    path: 'homework',
    element: <LessonHistory />
  },
  {
    path: 'homework/:id',
    element: <HomeWork />
  },
  {
    path: 'live-lessons',
    element: <LiveLessons />
  },
  {
    path: 'library',
    element: <LibraryNav />
  },
  {
    path: 'live-lessons',
    element: <LiveLessons />
  },
  {
    path: 'library',
    element: <TeacherReviews />
  },
  {
    path: 'messages',
    element: <Messages />
  },
  {
    path: 'notifications',
    element: <Notifications />
  }
]

export const teachersRouterData = [
  {
    path: 'home',
    element: <TeacherHome />
  },
  {
    path: 'classes',
    element: <Classes />
  },
  {
    path: 'classes/:id',
    element: <ClassesSingle />
  },
  {
    path: 'lessons',
    element: <Subjects />
  },

  {
    path: 'lessons/:subject_id',
    element: <LessonsSingle />
  },
  {
    path: 'lessons/:subject_id/:lesson_slug',
    element: <LessonsPlan />
  },
  {
    path: 'students/:uuid',
    element: <Students />
  },
  {
    path: 'statistics',
    element: <TeacherStatistics />
  },
  {
    path: 'settings',
    element: <TeacherSettings />
  }
]

export const routerData = [
  { path: 'sign-up', element: <Login /> },
  { path: 'code', element: <Login2 /> },
  { path: 'sign-in', element: <Login3 /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  {
    path: 'student',
    element: <FirstLayout menuItems={sideMenuData} />,
    children: [
      {
        path: 'home',
        element: (
          <SecondLayout menuData={homeMenuData} routerData={homeRouterData} parent='home' initial='/lesson-history' />
        ),
        children: homeRouterData
      },
      {
        path: 'school',
        element: (
          <SecondLayout menuData={schoolMenuData} routerData={schoolRouterData} parent='school' initial='/teachers' />
        ),
        children: schoolRouterData
      },
      {
        path: 'support',
        element: (
          <SecondLayout
            menuData={supportMenuData}
            routerData={supportRouterData}
            parent='support'
            initial='/requirements'
          />
        ),
        children: supportRouterData
      },
      {
        path: 'profile',
        element: (
          <SecondLayout
            menuData={profilesMenuData}
            routerData={profilesRouterData}
            parent='profile'
            initial='/settings'
          />
        ),
        children: profilesRouterData
      }
    ]
  },
  {
    path: 'teacher',
    element: <FirstLayout menuItems={[]} />,
    children: [
      {
        path: '',
        element: (
          <SecondLayout
            menuData={teachersMenuData}
            routerData={homeRouterData}
            parent='home'
            initial='/lesson-history'
          />
        ),
        children: teachersRouterData
      }
    ]
  }
]

import React, { SVGProps } from 'react'

function School(props: SVGProps<SVGSVGElement>) {
  return (
     <svg
              width={26}
              height={25}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              {...props}
            >
              <path
                className="orange"
                d="M16.0775 7.30888C16.0775 6.62125 15.7388 5.98494 15.1641 5.6052L11.0588 2.86494C10.3712 2.40309 9.46803 2.40309 8.7804 2.86494L4.67514 5.6052C4.11067 5.98494 3.76172 6.62125 3.76172 7.30888V13.1384C3.76172 13.4257 3.98751 13.6515 4.27488 13.6515H15.5644C15.8517 13.6515 16.0775 13.4257 16.0775 13.1384V7.30888ZM9.91961 11.0857C8.93435 11.0857 8.12356 10.2749 8.12356 9.28967C8.12356 8.30441 8.93435 7.49362 9.91961 7.49362C10.9049 7.49362 11.7157 8.30441 11.7157 9.28967C11.7157 10.2749 10.9049 11.0857 9.91961 11.0857Z"
                fill="currentColor"
              />
              <path
                d="M23.2628 21.8629H21.9594V18.784C22.9344 18.4658 23.6426 17.5524 23.6426 16.4748V14.4221C23.6426 13.0777 22.5444 11.9795 21.2 11.9795C19.8555 11.9795 18.7573 13.0777 18.7573 14.4221V16.4748C18.7573 17.5421 19.4552 18.4453 20.4097 18.7737V21.8629H16.0786V15.705C16.0786 15.4177 15.8528 15.1919 15.5655 15.1919H4.27601C3.98864 15.1919 3.76285 15.4177 3.76285 15.705V21.8629H2.73653C2.31574 21.8629 1.9668 22.2119 1.9668 22.6326C1.9668 23.0534 2.31574 23.4024 2.73653 23.4024H21.1384C21.1589 23.4024 21.1692 23.4127 21.1897 23.4127C21.2102 23.4127 21.2205 23.4024 21.241 23.4024H23.2628C23.6836 23.4024 24.0326 23.0534 24.0326 22.6326C24.0326 22.2119 23.6836 21.8629 23.2628 21.8629ZM9.15101 18.784C9.15101 18.3632 9.49995 18.0142 9.92074 18.0142C10.3415 18.0142 10.6905 18.3632 10.6905 18.784V21.8629H9.15101V18.784Z"
                fill="currentColor"
              />
            </svg>
  )
}

export default School
import React, { Fragment, ReactElement, useContext, useMemo } from 'react'
import ProfileInfo from '../first-layout/components/profile-info'
import ProfileInfoCard from '../first-layout/components/profile-menu-card'
import ProfileMenuList from '../first-layout/components/profile-menu-list'
import { StoryTime } from '../first-layout/components/story-time'
import Lang from '../../assets/img/icons/Lang'
import ChevronDownBlack from '../../assets/img/icons/ChevronDownBlack'
import CalendarNav from '../../assets/img/icons/CalendarNav'
import { profileMenuData, sideMenuData, teachersMenuData } from '../../data/menuData'
import { IProfileMenuItem } from '../first-layout/components/profile-menu-item'
import { Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const LANGUAGES = [
  {
    name: 'O’zbek',
    value: 'uz'
  },
  {
    name: 'English',
    value: 'en'
  },
  {
    name: 'Русский',
    value: 'ru'
  }
]

interface ISecondLayout {
  menuData: IProfileMenuItem[]
  initial: string
  parent: string
  routerData: {
    path: string
    element: ReactElement
  }[]
}

function SecondLayout(props: ISecondLayout) {
  const location = useLocation()
  const { user } = useContext(AuthContext)
  const { i18n, t } = useTranslation()
  console.log(location?.pathname)

  const page = useMemo(() => {
    const path = location?.pathname?.split('/')?.[2]
    console.log(path, 'path')
    console.log(sideMenuData?.[0].path?.[1], 'sideMenuData')
    if (user?.role === 'student') {
      return sideMenuData?.find(el => el.path?.split('/')?.[1] === path)
    } else {
      return teachersMenuData?.find(el => el.path?.split('/')?.[1] === path)
    }
  }, [location?.pathname, user?.role])
  return (
    <Fragment>
      {/* SIDE-INFO */}
      <div className='sideinfo'>
        <ProfileInfo />
        <div className='profile-menu'>
          {user?.role === 'student' && (
            <ProfileInfoCard>
              <ProfileMenuList data={profileMenuData} parent={props.parent} />
            </ProfileInfoCard>
          )}

          <ProfileInfoCard>
            <ProfileMenuList data={props.menuData} parent={props.parent} />
          </ProfileInfoCard>
          {user?.role === 'student' && <StoryTime />}
        </div>
      </div>

      {/* SIDEBAR MOBILE PHOTO*/}
      <div className='sidebar-photo'>
        <img src={require('../../assets/img/mock/student.jpg')} alt='student' />
      </div>

      <div className='support'>
        {/* HEADER */}
        <header className='header'>
          <div className='header__name'>{t(page?.title || '')}</div>

          <nav className='header-nav'>
            {/* Lang */}
            <div className='header-lang'>
              <div className='header-lang__btn'>
                <div className='header-lang__btn-wrap'>
                  <Lang />
                  <div>{LANGUAGES?.find(lang => lang.value === i18n.language)?.name}</div>
                  <ChevronDownBlack />
                </div>
              </div>
              <div className='header-lang__list'>
                {LANGUAGES?.map(lang => (
                  <a
                    key={lang?.value}
                    onClick={() => i18n.changeLanguage(lang.value)}
                    className={i18n.language === lang.value ? 'current' : ''}
                  >
                    {lang.name}
                  </a>
                ))}
              </div>
            </div>
            {/* Lang end */}

            {/* date,notification,message */}
            <div className='header-nav__menu'>
              <a href={`/${user?.role}/${props.parent}/calendar`} className='header-nav__date'>
                <CalendarNav />
                <span>{moment().format('DD.MM.YYYY')}</span>
              </a>
            </div>
            {/* END END END date , notification , message */}
          </nav>
        </header>

        <Outlet />
      </div>
    </Fragment>
  )
}

export default SecondLayout

import React from 'react'
import './ToastStyles.scss'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

function NotificationToast({ t, body, title }: any) {
  const navigate = useNavigate()
  return (
    <div className={`toast-container`}>
      <div className='toast-content' onClick={() => navigate('/student/school/messages')}>
        <div className='toast-header'>
          <div className='toast-image'>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
              alt=''
            />
          </div>
          <div className='toast-text'>
            <p className='toast-name'>{title}</p>
            <p className='toast-message'>{body}</p>
          </div>
        </div>
      </div>
      <div className='toast-close'>
        <button onClick={() => toast.dismiss(t.id)}>Close</button>
      </div>
    </div>
  )
}

export default NotificationToast

import * as React from "react"
import { SVGProps } from "react"
const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#FB9851"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M23.835 13c0 5.98-4.854 10.834-10.834 10.834-5.98 0-10.833-4.854-10.833-10.834 0-5.98 4.853-10.833 10.833-10.833S23.835 7.02 23.835 13Z"
    />
    <path
      stroke="#FB9851"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.02 16.445 13.66 14.44c-.585-.347-1.061-1.181-1.061-1.864V8.136"
    />
  </svg>
)
export default Clock

import React, { Fragment, useContext } from 'react'
import './App.css'
import './css/animate.css'
import './css/main.scss'
import './css/Calendar.css'
import './css/content-styles.css'
import './css/owl.carousel.css'
import 'swiper/css'
import { Navigate, Route, Routes } from 'react-router-dom'
import Providers from './providers'
import { Toaster } from 'react-hot-toast'
import { routerData } from './data/routerData'
import { AuthContext } from './context/AuthContext'

const renderRouter = (items: any[]) => {
  return (
    <Fragment>
      {items?.map(item =>
        item?.children ? (
          <Route path={item.path} element={item.element}>
            {renderRouter(item?.children)}
          </Route>
        ) : (
          <Route path={item.path} element={item.element} />
        )
      )}
    </Fragment>
  )
}

function App() {
  const { user } = useContext(AuthContext)

  return (
    <Providers>
      <div className='App'>
        <Routes>
          <Route
            path='/'
            element={
              <Navigate
                to={user ? (user?.role === 'student' ? '/student/home' : '/teacher/home') : '/sign-in'}
                replace
              />
            }
          />
          {renderRouter(routerData)}
        </Routes>
      </div>
      <Toaster position='top-center' reverseOrder={false} />
    </Providers>
  )
}

export default App

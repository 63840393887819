import { useState } from 'react'
import GetContainer from '../../../components/get-container'
import IndividualLessonItem from './components/IndividualLessonItem'
import LessonsFilter from './components/LessonsFilter'
import moment from 'moment'
import { ISubject, ITeacher } from '../../../types/types'

export const IndividualLessons = () => {
  const [filter, setFilter] = useState({
    subject_ids: [],
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    start_time: '07:00',
    end_time: '18:00',
    day_of_week: [1, 2, 3, 4, 5, 6, 7],
    gender: 'male',
    search: ''
  })
  console.log(filter, 'filter')
  return (
    <div className='individual-wrap'>
      <GetContainer
        hideLoading
        url='/student/subjects/self'
        onSuccess={async data => setFilter({ ...filter, subject_ids: data?.data?.map((item: ISubject) => item?.id) })}
      >
        {({ data }) => <LessonsFilter selfSubjects={data?.data} value={filter} setValue={setFilter} />}
      </GetContainer>

      <ul className='individual-list'>
        <GetContainer
          url='/student/groups/individual'
          params={{
            ...filter,
            day_of_week: filter?.day_of_week?.join(','),
            subject_ids: filter?.subject_ids?.join(','),
            end_date: moment(filter?.end_date).format('YYYY-MM-DD'),
            start_date: moment(filter?.start_date).format('YYYY-MM-DD')
          }}
        >
          {({ data }) =>
            data?.data?.map((teacher: ITeacher) =>
              teacher?.subjects?.map(subject =>
                teacher?.teacher_schedule?.map(schedule => (
                  <IndividualLessonItem key={schedule?.id} slot={schedule} subject={subject} teacher={teacher} />
                ))
              )
            )
          }
        </GetContainer>
      </ul>
    </div>
  )
}

import React, { SVGProps } from 'react'

function Support(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={26} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.1565 2.47461H6.84067C5.13698 2.47461 3.76172 3.83961 3.76172 5.52277V16.7199C3.76172 18.403 5.13698 19.768 6.84067 19.768H7.62067C8.44172 19.768 9.22172 20.0862 9.79646 20.6609L11.5515 22.3954C12.352 23.1857 13.6554 23.1857 14.4559 22.3954L16.2109 20.6609C16.7857 20.0862 17.5759 19.768 18.3867 19.768H19.1565C20.8601 19.768 22.2354 18.403 22.2354 16.7199V5.52277C22.2354 3.83961 20.8601 2.47461 19.1565 2.47461ZM11.3359 13.7743C11.7567 13.7743 12.1057 14.1233 12.1057 14.5441C12.1057 14.9649 11.7567 15.3138 11.3359 15.3138H8.5854C8.13382 15.3138 7.71303 15.0983 7.44619 14.7288C7.18961 14.3799 7.12803 13.9488 7.25119 13.5383C7.6104 12.4401 8.49303 11.8449 9.27303 11.3112C10.0941 10.757 10.5559 10.408 10.5559 9.81277C10.5559 9.27908 10.1249 8.84803 9.59119 8.84803C9.05751 8.84803 8.63672 9.28935 8.63672 9.82303C8.63672 10.2438 8.28777 10.5928 7.86698 10.5928C7.44619 10.5928 7.09725 10.2438 7.09725 9.82303C7.09725 8.44777 8.21593 7.31882 9.60146 7.31882C10.987 7.31882 12.1057 8.4375 12.1057 9.82303C12.1057 11.2701 11.0178 12.0091 10.1454 12.6043C9.60146 12.9738 9.0883 13.3228 8.83172 13.7846H11.3359V13.7743V13.7743ZM18.1301 13.8462H17.9146V14.5543C17.9146 14.9751 17.5657 15.3241 17.1449 15.3241C16.7241 15.3241 16.3751 14.9751 16.3751 14.5543V13.8462H14.3533C13.8504 13.8462 13.3886 13.5793 13.132 13.1483C12.8754 12.707 12.8754 12.163 13.132 11.732C13.8299 10.5312 14.6407 9.16619 15.3796 7.97566C15.708 7.45224 16.3341 7.21619 16.9191 7.3804C17.5041 7.55487 17.9146 8.08856 17.9044 8.70435V12.317H18.1301C18.5509 12.317 18.8999 12.6659 18.8999 13.0867C18.8999 13.5075 18.5509 13.8462 18.1301 13.8462Z'
        fill='currentColor'
      />
      <path
        d='M16.3761 12.3064V9.28906C15.7706 10.2743 15.1446 11.3314 14.5801 12.2962H16.3761V12.3064Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Support

import React from 'react'
import GetContainerPagination from '../../../../components/get-container-pagination'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ISlot } from '../../../../types/types'

function ClassesHistory() {
  const { uuid } = useParams()
  const { t, i18n } = useTranslation()
  return (
    <div className='students-class'>
      <h3 className='students__title'>{t('Class History')}</h3>
      <GetContainerPagination url={`/teacher/students/${uuid}/slots`} params={{ status: 'the_lesson_is_over' }}>
        {({ data }: { data: { data: ISlot[] } }) => (
          <div className='history-table'>
            <div className='history-table__head'>
              <div>{t('Date')}</div>
              <div>{t('Program')}</div>
              <div>{t('Teacher')}</div>
              <div>{t('Lesson Title')}</div>
            </div>
            {data?.data?.map(slot => (
              <div className='history-table__row' key={slot.id}>
                <div className='history-table__item'>{`${slot?.date} ${slot?.time}`}</div>
                <div className='history-table__item'>{slot?.schedule?.group?.subject?.name?.[i18n.language]}</div>
                <div className='history-table__item'>{`${slot?.schedule?.group?.teacher?.firstname} ${slot?.schedule?.group?.teacher?.lastname}`}</div>
                <div className='history-table__item'>{slot?.schedule?.lesson?.name?.[i18n?.language]}</div>
              </div>
            ))}
          </div>
        )}
      </GetContainerPagination>
    </div>
  )
}

export default ClassesHistory

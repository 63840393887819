import React, { useContext } from 'react'
import GetContainer from '../../../../../components/get-container'
import { ISlot } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'
import { RandomContext } from '../../../../../providers/RandomProvider'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'

interface IProps {
  id: string
}

const LessonInfo = (props: IProps) => {
  const { i18n, t } = useTranslation()
  const { mutate: cancel } = useCustomMutation('/student/slots/cancel', 'POST')
  const { setRandom } = useContext(RandomContext)
  return (
    <GetContainer url={'student/slots/' + props.id}>
      {({ data }: { data: { data: ISlot } }) => (
        <div className='moreLessonItem'>
          <div className='moreLessonItem-title'>
            <div className='lessonName'>{data?.data?.date}</div>
            <div className='lessonTime'>{data?.data?.time}</div>
          </div>
          <div className='content show'>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson')}: {data?.data?.schedule?.lesson?.name?.[i18n.language]}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson type')}: {data?.data?.schedule?.group?.type}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Status')}: {t(data?.data?.schedule?.main_status)}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Teacher')}:{' '}
                {`${data?.data?.schedule?.group?.teacher?.firstname} ${data?.data?.schedule?.group?.teacher?.lastname}`}
              </div>
            </div>
            {!!data?.data?.schedule?.zoom_link && (
              <div className='contentLesson'>
                <div className='contentLesson-title'>
                  <a href={data?.data?.schedule?.zoom_link}>{t('Zoom link')}</a>
                </div>
              </div>
            )}
          </div>
          <div className='content show' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='btn btn-transparent'
              onClick={() =>
                cancel(
                  {
                    schedule_id: data?.data?.schedule_id
                  },
                  {
                    onSuccess(data, variables, context) {
                      setRandom(Math.random())
                    }
                  }
                )
              }
            >
              {t('Cancel')}
            </button>
          </div>
        </div>
      )}
    </GetContainer>
  )
}

export default LessonInfo

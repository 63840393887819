import React from 'react'
import './AvatarComponent.scss'
import { Control, Controller } from 'react-hook-form'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'

interface IProps {
  control: Control
  name: string
}

const AvatarComponent = (props: IProps) => {
  const { mutate } = useCustomMutation('photo_upload?lang=ru', 'POST')
  const { t } = useTranslation()

  const handleAvatarChange = (event: any, cb: any) => {
    const file = event.target.files[0]
    const data = new FormData()
    data?.append('directory', 'avatar')
    data?.append('photo', file)
    mutate(data, {
      onSuccess(data) {
        cb(data?.data)
      }
    })
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <div className='avatar-container'>
          <img
            src={
              value
                ? MEDIA_URL + value
                : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
            }
            alt='Avatar'
            className='avatar-image'
          />
          <input type='file' id='avatarInput' accept='image/*' onChange={e => handleAvatarChange(e, onChange)} hidden />
          <label htmlFor='avatarInput' className='avatar-button'>
            {t('Change photo')}
          </label>
        </div>
      )}
    />
  )
}

export default AvatarComponent

import React, { SVGProps } from 'react'

function Coins(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={23} height={23} viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.4993 1.91699C6.20935 1.91699 1.91602 6.21033 1.91602 11.5003C1.91602 16.7903 6.20935 21.0837 11.4993 21.0837C16.7893 21.0837 21.0827 16.7903 21.0827 11.5003C21.0827 6.21033 16.7893 1.91699 11.4993 1.91699ZM15.0931 10.7816C15.486 10.7816 15.8118 11.1074 15.8118 11.5003C15.8118 11.8932 15.486 12.2191 15.0931 12.2191H8.14518V12.4587C8.14518 13.647 9.1131 14.6149 10.3014 14.6149H15.0931C15.486 14.6149 15.8118 14.9407 15.8118 15.3337C15.8118 15.7266 15.486 16.0524 15.0931 16.0524H10.3014C8.31768 16.0524 6.70768 14.4424 6.70768 12.4587V10.542C6.70768 8.55824 8.31768 6.94824 10.3014 6.94824H15.0931C15.486 6.94824 15.8118 7.27408 15.8118 7.66699C15.8118 8.05991 15.486 8.38574 15.0931 8.38574H10.3014C9.1131 8.38574 8.14518 9.35366 8.14518 10.542V10.7816H15.0931Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Coins

import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import Form from '../../../../components/form'
import CustomInput from '../../../../components/form/custom-input'
import { useTimer } from 'react-timer-hook'
import { Fragment } from 'react'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const validationSchema = Yup.object().shape({
  code: Yup.string().required('This field is required')
})
const initialValues = {
  code: ''
}

interface IProps {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  setPhone: React.Dispatch<React.SetStateAction<string>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  phone: string
}
interface IResendButtonProps {
  setToken: React.Dispatch<React.SetStateAction<string>>
  phone: string
}

const ResendButton = (props: IResendButtonProps) => {
  const { t } = useTranslation()
  const time = new Date()
  time.setSeconds(time.getSeconds() + 120)
  const { seconds, minutes, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => console.warn('onExpire called')
  })
  const { mutate } = useCustomMutation('forgot_password/send_code', 'POST')
  return (
    <Fragment>
      <span>{minutes}</span>:<span>{seconds}</span>
      <button
        className={`btn btn-orange ${isRunning ? 'disabled' : ''}`}
        disabled={isRunning}
        onClick={() =>
          mutate(
            { phone: props.phone },
            {
              onSuccess(data, variables, context) {
                props.setToken(data?.data?.reset_password_token)
              }
            }
          )
        }
        style={{ padding: '0.5rem', paddingRight: '1rem', paddingLeft: '1rem', marginLeft: '1rem' }}
      >
        {t('Resend code')}
      </button>
    </Fragment>
  )
}

export const SendCode = (props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {/*  LOGIN	*/}
      <section className='login'>
        <div className='login__wrap'>
          <div className='login__logo'>
            <img src={require('../../../../assets/img/logo.png')} alt='Onschool' />
          </div>
          <Form
            url='forgot_password/check_code'
            initialValues={initialValues}
            validationSchema={validationSchema}
            method='post'
            onSuccess={data => {
              props.setToken(data?.data?.reset_password_token)
              props.setStep(3)
            }}
          >
            {({ form, handleFinish }) => {
              const {
                control,
                formState: { errors },
                handleSubmit
              } = form
              return (
                <div className='login-form login-form__centered'>
                  <div className='login-form__title'>{t('Forgot password')}</div>
                  <CustomInput
                    control={control}
                    name='code'
                    label='Code'
                    placeholder='Write code'
                    error={errors?.code}
                  />
                  <ResendButton phone={props.phone} setToken={props.setToken} />
                  <div className='login-form__submit'>
                    <button
                      onClick={handleSubmit(data => {
                        handleFinish({ ...data, reset_password_token: props.token })
                      })}
                      className='btn btn-orange'
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
      </section>
    </>
  )
}

import React, { useState } from 'react'
import { SendPhone } from './components/SendPhone'
import { SendCode } from './components/SendCode'
import { ResetPassword } from './components/ResetPassword'

function ForgotPassword() {
  const [step, setStep] = useState(1)
  const [token, setToken] = useState('')
  const [phone, setPhone] = useState('')
  return (
    <div>
      {step === 1 && <SendPhone setToken={setToken} setPhone={setPhone} setStep={setStep} />}
      {step === 2 && <SendCode token={token} phone={phone} setToken={setToken} setPhone={setPhone} setStep={setStep} />}
      {step === 3 && <ResetPassword token={token} setToken={setToken} setPhone={setPhone} setStep={setStep} />}
    </div>
  )
}

export default ForgotPassword
